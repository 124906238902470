import { useEffect, useState, useMemo, useCallback } from 'react';
import { connect } from 'redux-bundler-react';
import Icon from '@components/icon/Icon';
import { AgGridReact } from 'ag-grid-react';
import { mdiAccountPlus, mdiCloseOctagon } from '@mdi/js';
import { Button } from '@trussworks/react-uswds';

import FieldHeader from '@forms/components/Form/FieldHeader';
import ContactFields from '@forms/components/Form/contact-fields/ContactFields';
import Card from '@components/card/card';
import EditCellRenderer from '@forms/components/gridCellRenderers/editCellRender';
import AddAgentModal from '@forms/components/modals/AddAgentModal';
import SelectInput from '@components/select/Select';
import ErrorSummary from '@components/error-summary/ErrorSummary';
import AgentAuthorizationUpload from '@forms/components/Form/AgentAuthorizationUpload';
import RightOfEntryUpload from '@forms/components/Form/RightOfEntryUpload';
import LinkButton from '@components/link/linkButton';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm, FormProvider } from 'react-hook-form';

import useErrorFocus from '@hooks/useErrorFocus';
import { ContactTypes, ContactsFormNames, ErrorMessages, FileTypes, ProfileRoles, TemplateFiles } from '@src/utils/enums';
import { phoneRegex, usPhoneRegex, countryCodeRegex, cityRegex, zipCodeRegex } from '@src/utils/regex';
import { addUserProfileAgent, updateUserProfileAgent, userProfileAgentUsed, updateGPAgentsValue } from '@forms/components/modals/_shared/UserProfileAgent';
import { stripUncompletedContacts, formatUSPhoneNumber } from '@src/utils/helpers';

import '@styles/index.scss';
import { contactInputValidation } from '@src/utils/validation/inputValidation.contact';

export const PermitsFormContactsMetadata = {
  sectionName: 'Contact Information',
  isSection: true,
  lastSection: false,
  firstSection: false,
};

const PermitsFormContacts = connect(
  'doDownloadFile',
  'doResetFileSection',
  'doClearContactType',
  'doUpdateSectionValidity',
  'doModalOpen',
  'doUpdateRequestContacts',
  'doUpdatePermitRequest',
  'doSaveTable',
  'selectTableData',
  'selectRequestAPIData',
  'selectAgentAuthorizationFile',
  'selectUserProfileData',
  'selectSteps',
  'selectActiveStep',
  'selectIsReadOnly',
  ({
    doDownloadFile,
    doResetFileSection,
    doClearContactType,
    doUpdateSectionValidity,
    doModalOpen,
    doUpdateRequestContacts,
    doUpdatePermitRequest,
    doSaveTable,
    tableData,
    requestAPIData,
    agentAuthorizationFile,
    userProfileData,
    steps,
    activeStep,
    isReadOnly,
    componentID,
    edit,
    stepNo
  }) => {
    const [rowData, setRowData] = useState([]);
    const [isUserProfileAgentUsed, setIsUserProfileAgentUsed] = useState(false);
    const applicantContact = useMemo(() => {
      const contacts = requestAPIData?.request?.contacts || [];
      return contacts.find((contact) => contact.contactType === ContactTypes.Applicant);
    }, [requestAPIData?.request?.contacts]);
    const hasHiredAgent = requestAPIData?.request?.permits?.[0]?.hasHiredAgent ? 'true' : (userProfileData.role === ContactTypes.Agent ? 'true' : 'false');
    const agentsTableLength = rowData?.length;

    const schema = (agentsTableLength) => yup.object().shape({
      ...contactInputValidation.nameRequired,
      ...contactInputValidation.addressMixed,
      city: yup.string().matches(cityRegex, ErrorMessages.Invalid).required(ErrorMessages.Required),
      state: yup.string().when('country', { is: 'US', then: () => yup.string().required(ErrorMessages.SelectOption), otherwise: () => yup.string().required(ErrorMessages.SelectOption) }),
      zipcode: yup.string().when('country', { is: 'US', then: () => yup.string().matches(zipCodeRegex, ErrorMessages.Invalid), otherwise: () => yup.string().nullable() }),
      country: yup.string().required(ErrorMessages.SelectOption),
      phoneOneType: yup.string().required(ErrorMessages.SelectOption),
      phoneOneCountryCode: yup.string().required(ErrorMessages.Required).matches(countryCodeRegex, ErrorMessages.Invalid),
      phoneOne: yup.string().when('phoneOneCountryCode', {
        is: '1', then: () => yup.string().required(ErrorMessages.Required).matches(usPhoneRegex, ErrorMessages.Invalid),
        otherwise: () => yup.string().when('phoneOneCountryCode', { is: val => val !== '1', then: () => yup.string().required(ErrorMessages.Required).matches(phoneRegex, ErrorMessages.Invalid) })
      }).required(ErrorMessages.Required),
      ...contactInputValidation.phoneOneExtensionOptional,
      showPhoneTwo: yup.boolean().nullable(),
      phoneTwoType: yup.string().nullable().when('showPhoneTwo', { is: true, then: () => yup.string().required(ErrorMessages.SelectOption) }),
      phoneTwoCountryCode: yup.string().nullable().when('showPhoneTwo', { is: true, then: () => yup.string().required(ErrorMessages.Required).matches(countryCodeRegex, ErrorMessages.Invalid) }),
      phoneTwo: yup.string().nullable().when(['phoneTwoCountryCode', 'showPhoneTwo'], {
        is: (countryCode, showPhoneTwo) => countryCode === '1' && showPhoneTwo === true, then: () => yup.string().required(ErrorMessages.Required).matches(usPhoneRegex, ErrorMessages.Invalid),
        otherwise: () => yup.string().nullable().when(['phoneTwoCountryCode', 'showPhoneTwo'], { is: (countryCode, showPhoneTwo) => countryCode !== '1' && showPhoneTwo === true, then: () => yup.string().required(ErrorMessages.Required).matches(phoneRegex, ErrorMessages.Invalid) })
      }),
      ...contactInputValidation.phoneTwoExtensionOptional,
      ...contactInputValidation.faxOptional,
      ...contactInputValidation.emailRequired,
      agentsTable: yup.boolean().when('agents', { is: (val) => val === 'true' && agentsTableLength < 1, then: () => yup.boolean().required('Agents: At least one agent is required') }),
      agents: yup.string().required(ErrorMessages.SelectOption),
      agentAuth: yup.string().when('agents', {
        is: 'true',
        then: () => yup.string()
          .required('Please provide an Agent Authorization Form')
          .test('file-invalid-test',
            'File selected is invalid',
            function (value) { return value !== 'error'; })
      }).nullable(),
      agentAuthComments: yup.string().when('agents', { is: 'true', then: () => yup.string().nullable() }),
      rightOfEntryFile: yup.string().test('right-of-entry-file-invalid-test',
        'Right-of-Entry: File selected is invalid',
        function (value) { return value !== 'error'; }
      ),
      ownerEntryComments: yup.string().nullable(),
    }, [contactInputValidation.faxOptionalDependencies]);

    const defaultValues = {
      salutation: applicantContact?.salutation ?? '',
      firstName: applicantContact?.firstName ?? '',
      middleName: applicantContact?.middleName ?? '',
      lastName: applicantContact?.lastName ?? '',
      address: applicantContact?.address ?? '',
      addressTwo: applicantContact?.addressTwo ?? '',
      city: applicantContact?.city ?? '',
      state: applicantContact?.state ?? '',
      zipcode: applicantContact?.zipcode ?? '',
      country: applicantContact?.country ?? 'US',
      phoneOneType: applicantContact?.phoneOneType ?? '',
      phoneOneCountryCode: applicantContact?.phoneOneCountryCode ?? '1',
      phoneOne: applicantContact?.phoneOne ? (applicantContact?.phoneOneCountryCode === '1' ? formatUSPhoneNumber(applicantContact.phoneOne) : applicantContact?.phoneOne) : '',
      phoneOneExtension: applicantContact?.phoneOneExtension ?? '',
      phoneTwoType: applicantContact?.phoneTwoType ?? '',
      phoneTwoCountryCode: applicantContact?.phoneTwoCountryCode ?? '',
      phoneTwo: applicantContact?.phoneTwo ? (applicantContact?.phoneTwoCountryCode === '1' ? formatUSPhoneNumber(applicantContact.phoneTwo) : applicantContact?.phoneTwo) : '',
      phoneTwoExtension: applicantContact?.phoneTwoExtension ?? '',
      faxCountryCode: applicantContact?.faxCountryCode ?? '',
      faxPhone: applicantContact?.faxPhone ?? '',
      emailAddress: applicantContact?.emailAddress ?? '',
      company: applicantContact?.company ?? '',
      agents: hasHiredAgent,
      agentAuthComments: requestAPIData?.request?.agentAuthComments ?? '',
      ownerEntryComments: requestAPIData?.request?.ownerEntryComments ?? '',
    };

    const methods = useForm({ resolver: yupResolver(schema(agentsTableLength)), mode: 'onBlur', defaultValues: defaultValues });
    const { formState: { isValid, errors }, watch, trigger, getValues, setValue, setError, clearErrors } = methods;

    const agents = watch('agents');
    const latestvalues = watch();
    const thisSectionStepStatus = useMemo(() => steps.find(step => step.id === stepNo)?.touched, [steps, stepNo]);

    const columnDefs = [
      { field: 'actions', headerName: 'Actions', width: 65, cellRenderer: 'editRowRenderer', cellRendererParams: { doModalOpen: doModalOpen, modalComponent: AddAgentModal, edit: true, setRowData: setRowData, rowData: rowData, isReadOnly: isReadOnly, userProfileKey: { firstName: userProfileData.firstName, lastName: userProfileData.lastName, email: userProfileData.emailAddress } } },
      { field: 'firstName', headerName: 'First Name', flex: 1, resizable: true },
      { field: 'middleName', headerName: 'Middle Name', flex: 1, resizable: true },
      { field: 'lastName', headerName: 'Last Name', flex: 1, resizable: true },
      { field: 'address', headerName: 'Address 1', flex: 1, resizable: true },
      { field: 'city', flex: 1, resizable: true },
      { field: 'state', flex: 1, maxWidth: 70 },
      { field: 'country', flex: 1, maxWidth: 70 },
    ];

    const clearRowData = useCallback(() => {
      setRowData([]);
      doClearContactType(ContactTypes.Agent);
      setIsUserProfileAgentUsed(false);
    }, [doClearContactType]);

    const handleAgentChange = (e) => {
      const stringToBool = e?.target?.value === 'true' ? true : false;
      doUpdatePermitRequest({ hasHiredAgent: stringToBool });
    };

    useEffect(() => {
      doUpdatePermitRequest({ hasHiredAgent: hasHiredAgent });
    }, [doUpdatePermitRequest, hasHiredAgent]);

    useEffect(() => {
      setIsUserProfileAgentUsed(userProfileAgentUsed(rowData, userProfileData?.firstName, userProfileData?.lastName, userProfileData?.emailAddress));
    }, [rowData, userProfileData]);

    useEffect(() => {
      // Load tables from database
      let initialRowData = requestAPIData?.request?.contacts?.filter((contact) => contact.contactType === ContactTypes.Agent);
      // If country is null, default to US
      initialRowData = initialRowData?.map(contact => contact.country === null ? { ...contact, country: 'US' } : contact);
      if (initialRowData) {
        //Update agents array if user profile agent exists
        if (userProfileAgentUsed(initialRowData, userProfileData?.firstName, userProfileData?.lastName, userProfileData?.emailAddress)) {
          initialRowData = updateUserProfileAgent(initialRowData, userProfileData);
        }
        updateGPAgentsValue(userProfileData?.role, hasHiredAgent, setValue, doUpdatePermitRequest);
        setRowData(initialRowData);
      }
    }, [requestAPIData, userProfileData, setValue, setRowData, hasHiredAgent, doUpdatePermitRequest]);

    useEffect(() => {
      const agentAuthValid = agents === 'false' || (agentAuthorizationFile?.length > 0 && rowData?.length > 0);
      const valid = isValid && agentAuthValid;
      doUpdateSectionValidity(PermitsFormContactsMetadata.sectionName, valid ? true : false, stepNo, isReadOnly);
    }, [isValid, agentAuthorizationFile, agents, rowData, doUpdateSectionValidity, stepNo, isReadOnly]);

    useEffect(() => {
      const { agents, agentAuth, agentAuthComments, propertyowners, showPhoneTwo, ...applicantFields } = latestvalues;
      const applicant = {
        ...applicantFields,
        contactID: applicantContact?.contactID ?? undefined,
        requestID: applicantContact?.requestID ?? undefined,
        version: applicantContact?.version ?? undefined,
        contactType: ContactTypes.Applicant,
        createdBy: applicantContact?.createdBy ?? undefined,
        phoneOne: applicantFields?.phoneOne?.replace(/\D/g, '') ?? undefined,
        phoneTwo: applicantFields?.phoneTwo?.replace(/\D/g, '') ?? undefined,
        faxPhone: applicantFields?.faxPhone?.replace(/\D/g, '') ?? undefined,
      };
      const contacts = [applicant, ...rowData, ...tableData?.adjoiningPropOwner];
      doUpdateRequestContacts(stripUncompletedContacts(contacts));
    }, [rowData, getValues, latestvalues, doUpdateRequestContacts, applicantContact, tableData?.adjoiningPropOwner]);

    // Reset Files if section is hidden
    useEffect(() => {
      if (agents === 'false') {
        clearRowData();
        doResetFileSection(ContactsFormNames.AgentAuthorization, true);
        doResetFileSection(ContactsFormNames.RightOfEntry, true);
      }
    }, [agents, clearRowData, doResetFileSection]);

    useEffect(() => {
      rowData && doSaveTable('agents', rowData);
    }, [rowData, doSaveTable]);

    useEffect(() => {
      if (agents === 'true' && rowData?.length < 1) {
        setError('agentsTable', { type: 'custom', message: 'Agents: At least one agent is required' });
      } else {
        clearErrors('agentsTable');
      }

    }, [agents, rowData?.length, setError, clearErrors]);

    useErrorFocus({ steps, stepNo, activeStep, trigger, isReadOnly });

    return (
      <FormProvider {...methods}>
        <>
          {errors && thisSectionStepStatus === 'true' && !isReadOnly &&
            <ErrorSummary errors={errors} isValid={isValid} sectionNo={stepNo} />
          }
          <FieldHeader
            text='Contact Information'
            subtext='Provide contact information for the applicant and the agent if applicable. The applicant is the individual
            or entity submitting the delineation report and/or requesting the
            jurisdictional determination. The agent is a third-party that has
            been retained by the applicant to act on their behalf in submitting
            this request to the U.S. Army Corps of Engineers. If you are an agent you must provide an Agent Authorization Form.
            Download an Agent Authorization form and upload the completed document in the file upload box below.'
          />
          {/* Contact Section comes first when role is not agent  */}
          {userProfileData?.role !== ProfileRoles.Agent && (
            <ContactFields type='applicant' label='Applicant' edit={edit} isReadOnly={isReadOnly} showButton />
          )}

          <FieldHeader text='Agents' />
          <div className='ml-2 mb-2' id='agentsTable'>
            <SelectInput name='agents' label='Has the applicant hired an agent to complete the application process?' required className='w-50' readOnly={isReadOnly || userProfileData?.role === ContactTypes.Agent} onChange={handleAgentChange} >
              <option key='2' value={true}>Yes</option>
              <option key='3' value={false}>No</option>
            </SelectInput>
          </div>
        </>

        {(agents === 'true') && (
          <>
            <Card>
              <div className='row d-flex w-100 mt-3 pb-3 justify-content-center'>
                <div className='d-flex justify-content-center col-3'>
                  <Button
                    className='add-agent-button'
                    title='Add an Agent'
                    size='small'
                    onClick={() => doModalOpen(AddAgentModal, { setRowData: setRowData, rowData: rowData, isReadOnly: isReadOnly })}
                    disabled={isReadOnly}
                  >
                    <Icon focusable={false} className='mr-1' path={mdiAccountPlus} size={'16px'} />
                    Add an Agent
                  </Button>
                </div>
                {userProfileData.role === ContactTypes.Agent &&
                    <div className='d-flex justify-content-center col-3'>
                      <Button
                        className={`table-btn-profile ${(isUserProfileAgentUsed || isReadOnly) ? 'disabled' : 'hover'}`}
                        title='Use Profile Data'
                        size='small'
                        onClick={() => addUserProfileAgent(userProfileData, rowData, setRowData, isUserProfileAgentUsed, setIsUserProfileAgentUsed)}
                        disabled={isReadOnly || isUserProfileAgentUsed}
                      >
                      Use Profile Data
                      </Button>
                    </div>}
                <div className='d-flex justify-content-center col-3'>
                  <Button
                    className={`clear-table-button ${(rowData.length === 0 || isReadOnly) ? 'disabled' : 'hover'}`}
                    title='Clear Agents'
                    size='small'
                    onClick={() => clearRowData('agent')}
                    disabled={rowData.length > 0 ? isReadOnly : true}
                  >
                    <Icon focusable={false} path={mdiCloseOctagon} size={'16px'} />
                    Clear Agents
                  </Button>
                </div>
              </div>
              <div className='ag-theme-balham' style={{ height: 400 }}>
                <AgGridReact
                  rowData={rowData}
                  columnDefs={columnDefs}
                  pagination={true}
                  paginationAutoPageSize={true}
                  rowHeight={35}
                  gridOptions={{
                    alwaysShowVerticalScroll: true
                  }}
                  components={{
                    'editRowRenderer': EditCellRenderer,
                  }}
                  suppressClickEdit
                ></AgGridReact>
              </div>
            </Card>
            <AgentAuthorizationUpload componentID={componentID} />
            <RightOfEntryUpload
              componentID={componentID}
              subText={<p>Note: If a site visit is needed, you, as the agent must also provide a <LinkButton onClick={() => doDownloadFile(TemplateFiles.RightOfEntry, FileTypes.Template)} title='Right of Entry form' content='Right of Entry form' />.</p>}
            />

            {/* Contact Section comes after Agent Section when role is agent */}
            {userProfileData.role === ProfileRoles.Agent && (
              <div className='ml-2 mb-2'>
                <ContactFields type='applicant' label='Applicant' edit={edit} isReadOnly={isReadOnly} showButton />
              </div>
            )}
          </>
        )}
      </FormProvider >
    );
  }
);
PermitsFormContacts.metadata = PermitsFormContactsMetadata;

export default PermitsFormContacts;
