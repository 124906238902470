import { toast } from 'react-toastify';
import { tSuccess, tError } from '../utils/toast-helpers';
import { createSelector } from 'redux-bundler';
import ProfileModal from '../app-pages/Forms/components/modals/Profile';
import isAnonymousModal from '../app-pages/Resources/components/modals/isAnonymous';
import NewApplicationModal from '../app-pages/Home/components/modals/NewApplication';
import { ProfileRoles } from '@src/utils/enums';

const profileBundle = {
  name: 'userProfile',

  getReducer: () => {
    const initialData = {
      data: [],
      params: {},
      isUserRoleValid: false,
    };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case 'UPDATE_USER_PROFILE_PARAMS':
          return {
            ...state,
            params: payload,
          };
        case 'UPDATE_USER_PROFILE':
          return {
            ...state,
            data: {
              address: payload.data.address,
              addressTwo: payload.data.addressTwo,
              agencyName: payload.data.agencyName,
              agencyType: payload.data.agencyType,
              areaOfInterest: payload.data.areaOfInterest,
              city: payload.data.city,
              country: payload.data.country,
              companyName: payload.data.companyName,
              emailAddress: payload.data.emailAddress,
              employeeType: payload.data.employeeType,
              firstName: payload.data.firstName,
              lastName: payload.data.lastName,
              middleName: payload.data.middleName,
              phone: payload.data.phone,
              phoneCountryCode: payload.data.phoneCountryCode,
              role: payload.data.role,
              salutation: payload.data.salutation,
              state: payload.data.state,
              zipcode: payload.data.zipcode,
              isDeveloper: payload?.data?.isDeveloper
            },
          };
        case 'UPDATE_IS_USER_ROLE_VALID':
          return { ...state, isUserRoleValid: payload };
        default:
          return state;
      }
    };
  },

  selectUserProfile: state => state.userProfile,
  selectUserProfileData: state => state.userProfile.data,
  selectUserProfileParams: state => state.userProfile.params,
  selectUserAreasOfInterest: state => state.userProfile.data.areaOfInterest,
  selectUserIsDeveloper: state => state?.userProfile?.data?.isDeveloper,
  selectIsUserRoleValid: state => state?.userProfile?.isUserRoleValid,

  doFetchUserProfile: () => ({ dispatch, apiGet, store }) => {
    const VCSession = sessionStorage.getItem('VCSession');
    const resourcesModuleSession = sessionStorage.getItem('ResourcesModuleSession');
    const permitButtonSession = sessionStorage.getItem('PermitButtonSession');

    store.doSetLoadingState(true);
    store.doSetLoadingMessage('Fetching User Profile...');

    const uri = '/api/users/getUserProfile';

    apiGet(uri, (err, body) => {
      if (!err && body?.status === 'User Found') {
        store.doSetLoadingState(false);
        store.doSetLoadingMessage('Loading...');
        dispatch({ type: 'UPDATE_USER_PROFILE', payload: body });
        store.doUpdateUserProfileParams();
        store.doGetLookupTableData();
        store.doUpdateIsUserRoleValid();

        // Control modals depending on user's current active session
        (VCSession || resourcesModuleSession || permitButtonSession) && store.doUpdateRelativeUrl('/home');
        VCSession && store.doModalOpen(isAnonymousModal);
        resourcesModuleSession && store.doModalOpen(NewApplicationModal);
        permitButtonSession && store.doModalOpen(NewApplicationModal);
        sessionStorage.removeItem('ResourcesModuleSession');
        sessionStorage.removeItem('PermitButtonSession');

        (body?.data?.role === ProfileRoles.ExternalAgency) && store.doModalOpen(ProfileModal, { edit: true });
      } else if (!err && body?.status === 'NoUser Found') {
        store.doSetLoadingState(false);
        store.doSetLoadingMessage('Loading...');
        store.doModalOpen(ProfileModal);
      } else {
        store.doSetLoadingState(false);
        store.doSetLoadingMessage('Loading...');
        store.doAuthLogout();
        store.doUpdateRelativeUrl('/home');
        toast.error('There was a network issue, please check your network connection and try again.');
      }
    });
  },

  doSaveUserProfile: (formData) => ({ dispatch, apiPost, store }) => {
    const toastId = toast.loading('Creating User Profile...');

    const url = '/api/users/addUser';

    apiPost(url, formData, (err, body) => {
      if (!err && body.status === 'Success') {
        dispatch({ type: 'USER_PROFILE_UPDATE_FINISHED' });
        tSuccess(toastId, 'User profile created successfully!');
        store.doModalClose();
        store.doFetchUserProfile();
      } else {
        dispatch({ type: 'USER_PROFILE_UPDATE_ERROR', payload: err });
        tError(toastId, 'User profile creation failed!');
      }
    });
  },

  doUpdateUserProfile: (formData, callback = null) => ({ dispatch, store, apiPut }) => {
    const toastId = toast.loading('Updating User Profile...');

    const uri = '/api/users/updateUserProfile';

    apiPut(uri, formData, (err, body) => {
      if (!err && body.status === 'Success') {
        store.doFetchUserProfile();
        if (callback && typeof callback === 'function') {
          callback();
        }
        tSuccess(toastId, body.msg);
        store.doModalClose();
      } else {
        dispatch({ type: 'PUT_USER_PROFILE_ERROR', payload: body.msg ? body.msg : err });
        tError(toastId, body.msg ? body.msg : err);
      }
    });
  },

  doUpdateIsUserRoleValid: () => ({ dispatch, store }) => {
    if (store.selectAuthIsLoggedIn()) {
      if (store.selectUserProfileData()?.role) {
        dispatch({ type: 'UPDATE_IS_USER_ROLE_VALID', payload: store.selectUserProfileData().role === ProfileRoles.ExternalAgency ? false : true });
      } else {
        dispatch({ type: 'UPDATE_IS_USER_ROLE_VALID', payload: false });
      }
    } else {
      dispatch({ type: 'UPDATE_IS_USER_ROLE_VALID', payload: false });
    }
  },

  doUpdateUserProfileParams: () => ({ dispatch, store }) => {
    const params = {
      firstName: store.selectUserProfileData().firstName,
      lastName: store.selectUserProfileData().lastName,
      email: store.selectUserProfileData().emailAddress,
    };
    dispatch({ type: 'UPDATE_USER_PROFILE_PARAMS', payload: params });
  },

  selectUserAreasOfInterestDivisions: createSelector('selectUserAreasOfInterest', (payload) => {
    if (payload !== undefined) {
      return payload.filter(item => String(item).length === 1);
    } else {
      return [];
    }
  }),

  selectUserAreasOfInterestDistricts: createSelector('selectUserAreasOfInterest', (payload) => {
    if (payload !== undefined) {
      return payload.filter(item => String(item).length > 1);
    } else {
      return [];
    }
  }),
};

export default profileBundle;
