export const enum ApplicableStatutoryAuthorities {
  Section10 = 'Section 10 of the Rivers and Harbors Act of 1899 (33 U.S.C. 403)',
  Section103 = 'Section 103 of the Marine Protection, Research, and Sanctuaries Act of 1972, as amended (33 U.S.C. 1413)',
  Section404 = 'Section 404 of the Clean Water Act (33 U.S.C. 1344)',
}

export const enum ContactsFormNames {
  AgentAuthorization = 'Agent Authorization',
  RightOfEntry = 'Right of Entry',
}

export const enum ContactTypes {
  AdjoiningPropertyOwner = 'Adjoining Property Owner',
  Agent = 'Agent',
  Applicant = 'Applicant',
  PropertyOwner = 'Property Owner',
  Reporter = 'Reporter',
  SuspectContractor = 'Suspect - Contractor',
  SuspectOther = 'Suspect - Other',
  SuspectPropertyOwner = 'Suspect - Property Owner',
  Witness = 'Witness',
}

export const enum ErrorMessages {
  FileEmpty = 'File is empty. Please select another file.',
  FileExists = 'This file name has already been used. Please rename file or select another.',
  FileNameInvalidCharacters = 'File name contains invalid characters. Please rename file. File name can only contain alphanumeric characters, whitespace, and the following special characters: !, -, _, ., (, ), &, $, @, =, ;, +, ,',
  FileNameTooLong = 'File name is exceeds character limit (> 1024 characters). Please shorten the file name.',
  FileTooLarge = 'File is too large (> 100MB). Please compress file, parse into separate files, or select another file.',
  GreatThanZero = 'Value must be more than 0',
  Invalid = 'Field is invalid',
  InvalidCharacters = 'Invalid character(s) have been removed. Currently, we cannot accept any accent characters i.e (è, ã, ñ).',
  InvalidCoordinates = 'File contained invalid values. Please enter the values manually for the highlighted fields.',
  InvalidDistrict = 'A USACE regulatory district could not be determined for all CSV coordinates, Please verify all coordinates within the csv and try again.',
  InvalidHeaders = 'File contained invalid header values. Please verify the headers of the CSV file and re-upload.',
  RestrictedChars = 'Only letters, numbers, spaces, commas, periods, hyphens, apostrophes, forward slashes, and hash symbols are allowed.',
  OverTotalFileCount = 'Only 50 files can be uploaded per save. Please hit "Save Progress" to upload your files and try again.',
  OverTotalFileLimit = 'Only 500MB of files can be uploaded per save. Please hit "Save Progress" to upload your files and try again.',
  Required = 'Field is required',
  SelectOption = 'Please select an option',
  UploadNotice = 'Individual files cannot exceed 100MB, and in total cannot exceed 500MB per save.',
}

export const enum FileTypes {
  Request = 'Request',
  Resource = 'Resource',
  Template = 'Template',
}

export const enum GeometryTypes {
  LineString = 'LineString',
  MultiLineString = 'MultiLineString',
  MultiPoint = 'MultiPoint',
  MultiPolygon = 'MultiPolygon',
  Point = 'Point',
  Polygon = 'Polygon',
}

export const enum ImpactsActivity {
  ConversionWatersType = 'Conversion of waters type (forested wetland to emergent wetland, stream to lake)',
  DischargeDredgedMaterial = 'Discharge of dredged material',
  DischargeFillMaterial = 'Discharge of fill material',
  DredgingSection = 'Dredging (Section 10)',
  EcologicalRestoration = 'Ecological restoration',
  Other = 'Other (Aquaculture, Work, Aerial or Submarine cable crossings)',
  Removal = 'Removal (Sec 10 structures)',
  Structure = 'Structure (Sec 10 only)',
  TransportDredgedMaterial = 'Transport of dredged material (Sec 103)',
}

export const enum IPACSupportedFootprintGeometryType{
  Polygon = 'Polygon',
  MultiPolygon = 'MultiPolygon'
}

export const enum MitigationTypes {
  AdvancedPermitteeResponsible = 'Advanced Permittee Responsible Mitigation',
  InLieuFee = 'In-lieu Fee',
  MitigationBank = 'Mitigation Bank',
}

export const enum PhoneTypes {
  Business = 'Business',
  Mobile = 'Mobile',
  Primary = 'Primary',
  Residence = 'Residence',
  Secondary = 'Secondary',
}

export const enum PreAppRequestTypes {
  MitigationBankOrFee = 'Mitigation bank or in-lieu fee',
  NeedAdvice = 'I need advice on what type of permit is applicable',
  ScopingResponse = 'Scoping response for environmental compliance (NEPA, state/local environmental scoping requirements, etc.)',
  StandardPermit = 'Standard permit',
}

export const enum ProfileRoles {
  Agent = 'Agent',
  Anonymous = 'Anonymous',
  Applicant = 'Applicant',
  ExternalAgency = 'External Agency',
  USACERegulator = 'USACE Regulator',
}

export const enum ResourceFiles {
  AdditionalInformation = 'JD_Additional_info.pdf',
  AppendixA1 = 'Appx_A1_Adjoining_Property_Owners.pdf',
  AppendixB = 'Appx_B_Aquatic_Resources.pdf',
  AppendixC = 'Appx_C_Impacts.pdf',
  AppendixD1 = 'Appx_D1_MB_ILF.pdf',
  AppendixD2 = 'Appx_D2_PRM.pdf',
  AppendixE = 'Appx_E_Dredging.pdf',
  AppendixF = 'Appx_F_Shoreline_Stabilization.pdf',
  AppendixG = 'Appx_G_Pile_Driving.pdf',
  AppendixH = 'Appx_H_Supporting_Files.pdf',
  ARBulkUploadCSV = 'Aquatic_Resource_Bulk_Upload_Template.csv',
  ARBulkUploadGDBTemplate = 'Aquatic_Resource_Bulk_Upload.gdb.zip',
  ARBulkUploadGuide = 'Aquatic_Resource_Bulk_Upload_Guide.pdf',
  DistrictList = 'JPA_Districts_14MAY2024.pdf',
  DistrictSpecificInfo = 'District_Specific_Information_14MAY2024.pdf',
  Eng4345 = 'Eng_Form_4345_2024Jun.pdf',
  Eng4345ProjectInfo = 'IP_Additional_Info.pdf',
  Eng6082 = 'Eng_Form_6082-2024Jul-v2.pdf',
  Eng6247 = 'Eng_Form_6247-Jurisdictional_Determination.2022Jul.pdf',
  Eng6284 = 'Eng_Form_6284_VC.pdf',
  Eng6286 = 'ENG_6286_PreApp.pdf',
  Eng6294 = 'Eng_Form_6294_Right_Of_Entry.2024Jan.pdf',
  Eng6295 = 'Eng_Form_6295-Agent_Authorization.2024Jan.pdf',
  LoginGovHelpGuide = 'Login_gov_guide.pdf',
  Reg101FreqUsedTerms = '20240618_RRS Regulatory Terms_Final.pdf',
  SampleDrawingBankStabilizationBioEng = 'Sample Drawing_Bank Stabilization (Bioengineered) 20240515.pdf',
  SampleDrawingBankStabilizationRipRap = 'Sample Drawing_Bank Stabilization (Riprap)_20240515.pdf',
  SampleDrawingCulvert = 'Sample Drawing_Culvert_20240318.pdf',
  SampleDrawingDock = 'Sample Drawing_Dock_20240226.pdf',
  SampleDrawingStream = 'Sample Drawing_Stream Crossing_20240304.pdf',
  SampleDrawingWetlandHouse = 'Sample Drawing_Wetland Fill for House_20240514.pdf',
  SampleDrawingWetlandRoad = 'Sample Drawing_Wetland Fill for Road_20240426.pdf',
}

export const RequestTypeStrings = {
  1: 'Submit a WOTUS Delineation Report and Aquatic Resource Inventory',
  2: 'Request a Jurisdictional Determination',
  3: 'Individual Permit (ENG 4345)',
  4: 'Nationwide Permit (ENG 6082)',
  5: 'Pre-application/Project Scoping',
  6: 'Violation Complaint',
};

export const enum TemplateFiles {
  AgentAuthorization = 'ENG6295_Agent_Authorization.pdf',
  Amended2023RuleGDB = 'Amended_2023Rule.gdb.zip',
  BulkUploadGuide = 'Aquatic_Resource_Bulk_Upload_Guide.pdf',
  PostSackettGDB = 'Pre2015_Post_Sackett.gdb.zip',
  ProjectLocationGDBTemplate = 'ProjectArea_BLANK.gdb.zip',
  PropertyOwnerBulk = 'Property_Owner_Template_v1.xlsx',
  RightOfEntry = 'ENG6294_Right_Of_Entry.pdf',
}
