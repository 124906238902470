import React, { useEffect } from 'react';
import { Alert } from '@trussworks/react-uswds';

import MultiSelectInput from '@components/new-inputs/multiSelectInput';

import { questionSet1, questionSet2 } from './helper';

import { useFormContext } from 'react-hook-form';

import { RequestTypeStrings } from '@src/utils/enums';
import ExternalLink from '@components/external-link/ExternalLink';


const Questions = () => {
  const { watch, setValue } = useFormContext();

  const watchQuestionOne = watch('questionOne');
  const watchRequestType = watch('requestType');

  const handleSelect = val => {
    setValue('requestType', val);
  };

  useEffect(() => {
    setValue('questionTwo', null);
    setValue('questionThree', null);
    setValue('requestType', null);
  }, [watchQuestionOne, setValue]);

  return (
    <form>
      <div className='container-fluid' style={{ height: 'auto', minHeight:'400px' }}>
        <div className='row mt-3'>
          <div className='col'>
            <MultiSelectInput name='questionOne' label='Please select a statement:' options={questionSet1} closeMenuOnSelect={true} required />
          </div>
        </div>
        {watchQuestionOne && (
          <div className='row mt-3'>
            <div className='col'>
              <MultiSelectInput name='questionTwo' label='Please select a statement:' options={questionSet2[watchQuestionOne?.value]} closeMenuOnSelect={true}
                required
                handleChange={val => {
                  if (val.value != null) {
                    handleSelect([val.value.toString()]);
                  } else {
                    // reset remaining values
                    setValue('questionThree', null);
                    setValue('requestType', null);
                  }
                }}
              />
            </div>
          </div>
        )}
        {watchRequestType && (
          <div className='row mt-3'>
            <div className='col'>
              <p>Based on your answers, you need to submit a <span className='text-bold'>{RequestTypeStrings[watchRequestType]}</span> request.</p>
              {watchRequestType[0] === '2' && (
                <Alert className='mb-2' type='warning' heading='NOTICE:' headingLevel='h4'>
                  <p>It is agency practice to honor requests for jurisdictional determinations unless it is clearly impracticable to do so, such as when the U.S. Army Corps of Engineers (USACE) is unable to gain access to a site to complete a jurisdictional determination or the USACE lacks other information necessary to respond to the request based on a sound technical record.</p>
                  <p>A jurisdictional determination typically requires a delineation of aquatic resources on the specified property. Persons who would like to request a Corps JD may include an aquatic resources delineation with their request to facilitate timely processing by the USACE. There may be a delay if the USACE needs to conduct the delineation, especially if the project site is large or contains many wetlands, other special aquatic sites, and other waters. Please contact your local USACE Regulatory office to determine current processing times.</p>
                </Alert>
              )}
              {(watchRequestType[0] === '3' || watchRequestType[0] === '4') && (
                <Alert className='mb-2' type='warning' heading='NOTICE:' headingLevel='h4'>
                  <p>Please use this link to apply for a permit: <ExternalLink href='https://www.usace.army.mil/Missions/Civil-Works/Regulatory-Program-and-Permits/Obtain-a-Permit/' />. You may need to contact your local USACE District for more information based on their local-level procedures: <ExternalLink href='https://regulatory.ops.usace.army.mil/offices/' />.</p>
                </Alert>
              )}
              <p>Press the <span className='text-bold'>Continue</span> button below to navigate to your request. </p>
            </div>
          </div>
        )}
      </div>
    </form>
  );
};

export default Questions;
