import { useEffect } from 'react';
import { connect } from 'redux-bundler-react';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm, FormProvider } from 'react-hook-form';

import { ModalContent, ModalFooter } from '@components/modal';
import ContactFields from '@forms/components/Form/contact-fields/ContactFields';
import { ErrorMessages } from '@src/utils/enums';

import { ContactTypes, ProfileRoles } from '@src/utils/enums';
import {
  cityRegex,
  countryCodeRegex,
  phoneRegex,
  usPhoneRegex,
  zipCodeRegex,
} from '@src/utils/regex';
import { hasDuplicateAgent, isCurrentUser } from './_shared/UserProfileAgent';
import ErrorSummary from '@components/error-summary/ErrorSummary';
import { contactInputValidation } from '@src/utils/validation/inputValidation.contact';

const schema = yup.object().shape({
  ...contactInputValidation.nameRequired,
  ...contactInputValidation.addressMixed,
  city: yup.string().matches(cityRegex, ErrorMessages.Invalid).required(ErrorMessages.Required),
  state: yup.string().when('country', {
    is: 'US',
    then: () => yup.string().required(ErrorMessages.SelectOption),
    otherwise: () => yup.string().required(ErrorMessages.SelectOption),
  }),
  zipcode: yup.string().when('country', {
    is: 'US',
    then: () => yup.string().matches(zipCodeRegex, ErrorMessages.Invalid),
    otherwise: () => yup.string().nullable(),
  }),
  country: yup.string().required(ErrorMessages.SelectOption),
  phoneOneType: yup.string().required(ErrorMessages.Required),
  phoneOneCountryCode: yup.string().required(ErrorMessages.Required).matches(countryCodeRegex, ErrorMessages.Invalid),
  phoneOne: yup
    .string()
    .when('phoneOneCountryCode', {
      is: '1',
      then: () => yup.string().matches(usPhoneRegex, ErrorMessages.Invalid),
      otherwise: () => yup.string().matches(phoneRegex, ErrorMessages.Invalid),
    })
    .required(ErrorMessages.Required),
  ...contactInputValidation.phoneOneExtensionOptional,
  showPhoneTwo: yup.boolean().nullable(),
  phoneTwoType: yup
    .string()
    .nullable()
    .when('showPhoneTwo', { is: true, then: () => yup.string().required(ErrorMessages.Required) }),
  phoneTwoCountryCode: yup
    .string()
    .nullable()
    .when('showPhoneTwo', {
      is: true,
      then: () => yup.string().required(ErrorMessages.Required).matches(countryCodeRegex, ErrorMessages.Invalid),
    }),
  phoneTwo: yup
    .string()
    .nullable()
    .when(['phoneTwoCountryCode', 'showPhoneTwo'], {
      is: (countryCode, showPhoneTwo) => countryCode === '1' && showPhoneTwo === true,
      then: () => yup.string().required(ErrorMessages.Required).matches(usPhoneRegex, ErrorMessages.Invalid),
      otherwise: () =>
        yup
          .string()
          .nullable()
          .when(['phoneTwoCountryCode', 'showPhoneTwo'], {
            is: (countryCode, showPhoneTwo) => countryCode !== '1' && showPhoneTwo === true,
            then: () => yup.string().required(ErrorMessages.Required).matches(phoneRegex, ErrorMessages.Invalid),
          }),
    }),

  ...contactInputValidation.phoneTwoExtensionOptional,
  ...contactInputValidation.faxOptional,
  ...contactInputValidation.emailRequired,
}, [contactInputValidation.faxOptionalDependencies]);

const AddAgentModal = connect(
  'selectUserProfileData',
  'doModalClose',
  ({ data, doModalClose, edit, id, isReadOnly, rowData, rowIndex, setRowData, userProfileData }) => {
    const defaultValues = {
      address: data?.address ?? null,
      addressTwo: data?.addressTwo ?? null,
      city: data?.city ?? null,
      company: data?.company ?? null,
      contactType: ContactTypes.Agent,
      country: data?.country ?? 'US',
      emailAddress: data?.emailAddress ?? null,
      faxCountryCode: data?.faxCountryCode ?? null,
      faxPhone: data?.faxPhone ?? null,
      firstName: data?.firstName ?? null,
      lastName: data?.lastName ?? null,
      middleName: data?.middleName ?? null,
      phoneOne: data?.phoneOne ?? null,
      phoneOneCountryCode: data?.phoneOneCountryCode ?? '1',
      phoneOneExtension: data?.phoneOneExtension ?? null,
      phoneOneType: data?.phoneOneType ?? null,
      phoneTwo: data?.phoneTwo ?? null,
      phoneTwoCountryCode: data?.phoneTwoCountryCode ?? null,
      phoneTwoExtension: data?.phoneTwoExtension ?? null,
      phoneTwoType: data?.phoneTwoType ?? null,
      salutation: data?.salutation ?? null,
      state: data?.state ?? null,
      zipcode: data?.zipcode ?? null,
    };
    const methods = useForm({ defaultValues: defaultValues, resolver: yupResolver(schema), mode: 'onBlur' });
    const {
      formState: { errors, isValid },
      setError,
      setFocus,
      getValues,
      trigger,
    } = methods;

    const onSave = () => {
      const contactFields = getValues();
      if (userProfileData?.role !== ProfileRoles.Agent && isCurrentUser(userProfileData, contactFields)) {
        setError('firstName', { type: 'manual', message: 'You cannot add yourself as an agent' });
        return;
      }

      if (isValid) {
        if (hasDuplicateAgent(rowData, contactFields, rowIndex)) {
          setError('firstName', { type: 'manual', message: 'Duplicate Agents are not allowed' });
          return;
        }

        const contactFieldsWithIDs = {
          ...contactFields,
          contactID: data?.contactID ?? undefined,
          contactType: ContactTypes.Agent,
          createdBy: data?.createdBy ?? undefined,
          phoneOne: contactFields?.phoneOne?.replace(/\D/g, '') ?? undefined,
          phoneTwo: contactFields?.phoneTwo?.replace(/\D/g, '') ?? undefined,
          faxPhone: contactFields?.faxPhone?.replace(/\D/g, '') ?? undefined,
          requestID: data?.requestID ?? undefined,
          version: data?.version ?? undefined,
        };

        if (edit) {
          let dataArr = [...rowData];
          const index = data.contactID ? dataArr.findIndex((el) => el.contactID === id) : id;
          dataArr[index] = contactFieldsWithIDs;
          setRowData(dataArr);
        } else {
          setRowData((rowData) => [...rowData, contactFieldsWithIDs]);
        }
        doModalClose();
      } else {
        trigger();
      }
    };

    useEffect(() => {
      errors?.[Object.keys(errors)[0]]?.['ref']?.focus?.();

      setFocus(errors?.[Object.keys(errors)[0]]?.['ref']?.['id']);
    }, [errors, setFocus]);

    return (
      <FormProvider {...methods}>
        <ModalContent hasCloseButton={isReadOnly} title={`${edit ? 'Edit' : 'Add'} Agent`}>
          {errors && <ErrorSummary errors={errors} modalID='addAgentModal' type='modal' />}
          <section className='modal-body' id='addAgentModal'>
            <div className='container-fluid'>
              <ContactFields
                edit={edit}
                isReadOnly={isReadOnly}
                label='Agent'
                showButton={userProfileData.role === ContactTypes.Agent}
                type='Agent'
              />
            </div>
          </section>
          <ModalFooter
            customClosingLogic
            onSave={onSave}
            saveText={edit ? 'Apply Changes' : 'Add'}
            showCancelButton={!isReadOnly}
            showSaveButton={!isReadOnly}
          />
        </ModalContent>
      </FormProvider>
    );
  }
);

export default AddAgentModal;
