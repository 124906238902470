import { Logo, Identifier, IdentifierMasthead, IdentifierLogo, IdentifierIdentity, IdentifierLinks, IdentifierLinkItem, IdentifierGov} from '@trussworks/react-uswds';
import ExternalLink from '@components/external-link/ExternalLink';
import cwbiLogo from '@styles/images/CWBI_Logo-Small.png';
import armyStar from '@styles/images/armystar-logo-rb.svg';
import usaceLogo from '@styles/images/usace-logo-color.svg';

import './footer.scss';

const links = [
  {content:'Accessibility support' ,href:'https://dodcio.defense.gov/DoDSection508/Std_Stmt.aspx'},
  {content:'Contact Us' ,href:'https://www.usace.army.mil/Contact.aspx'},
  {content:'Quality Act' ,href:'https://www.usace.army.mil/Information-Quality-Act/'},
  {content:'Link Disclaimer' ,href:'https://www.usace.army.mil/Link-Disclaimer/'},
  {content:'Privacy & Security' ,href:'https://www.usace.army.mil/Privacy-and-Security/'},
  {content:'No Fear Act' ,href:'https://prhome.defense.gov/nofear'},
  {content:'EEO & SHARP' ,href:'https://www.usace.army.mil/Careers/EEO/'},
  {content:'Small Business' ,href:'https://www.usace.army.mil/Business-With-Us/Small-Business/'},
  {content:'Open Government' ,href:'https://open.defense.gov/'},
  {content:'Office of the Inspector General' ,href:'https://www.usace.army.mil/About/Offices-and-Units/Engineer-Inspector-General/'},
  {content:'FOIA requests' ,href:'https://www.usace.army.mil/FOIA.aspx'},
  {content:'iSALUTE' ,href:'https://www.inscom.army.mil/isalute/'},
  {content:'Veterans Crisis Line' ,href:'https://www.veteranscrisisline.net/get-help-now/military-crisis-line/'},
];

const linkList = links.map(link => 
  <IdentifierLinkItem key={link.content}><ExternalLink className='footer-link' content={link.content} href={link.href}/></IdentifierLinkItem>
);

const identifierGovContent = (
  <>
    Looking for U.S. government information and services?{' '}
    <ExternalLink content='Visit USA.gov' href='https://usa.gov' />
  </>
);

const RRSFooter = (props) => {
  const {version} = props;

  return(
    <Identifier className='margin-top-15 padding-top-105 z-top'>
      <IdentifierMasthead aria-label='Agency identifier'>
        <IdentifierLogo href='https://www.army.mil'>
          <Logo size='small' image={<img
            className='army-star-logo'
            alt='U.S. Army'
            src={armyStar}
            height={50}
          />}/>
        </IdentifierLogo>
        <IdentifierLogo href='https://usace.army.mil'>
          <Logo size='small' image={<img
            className='usace-logo'
            alt='USACE Logo'
            src={usaceLogo}
            height={50}
          />}/>
        </IdentifierLogo>
        <IdentifierIdentity domain='rrs.usace.army.mil' className='margin-left-105'>
          <span aria-hidden='true'>An</span>
          {' official website of the '}
          <ExternalLink href='https://www.army.mil' content='United States Army'/>
          {' and the '}
          <ExternalLink href='https://usace.army.mil' content='U.S. Army Corps of Engineers'/>
        </IdentifierIdentity>
      </IdentifierMasthead>
      <IdentifierLinks navProps={{
        'aria-label': 'Important links'
      }}>
        {linkList}
      </IdentifierLinks>
      <IdentifierGov aria-label='U.S. government information and services'>
        {identifierGovContent}
      </IdentifierGov>
      <IdentifierGov aria-label='Civil Works Business Intelligence'>
        <div className='display-flex flex-align-center'>
          <Logo 
            size='small' 
            image={
              <img
                className='cwbi-logo'
                alt='CWBI Logo'
                src={cwbiLogo}
                height={50}
              />
            }
          />
          <span className='margin-left-1'>Powered by Civil Works Business Intelligence (CWBI)</span>
          <span className='margin-left-auto'title='Version'>RRS v{version}</span>
        </div>
      </IdentifierGov>
    </Identifier>
  );
};

export default RRSFooter;