import { useEffect } from 'react';
import { connect } from 'redux-bundler-react';
import Tooltip from '../tooltip/tooltip';
import { Textarea } from '@trussworks/react-uswds';
import { Controller, useFormContext } from 'react-hook-form';
import Creatable from 'react-select/creatable';

import { customSelectStyles, customSelectStylesNonRequired } from '@forms/input-forms/_helper';
import genericSecondaryModal from '@forms/components/modals/genericSecondaryModal';

import { isValidASCII } from '@src/utils/helpers';
import { ErrorMessages } from '@src/utils/enums';

import '@styles/index.scss';

const CreatableSelectInput = connect('doSecondaryModalOpen', ({
  doSecondaryModalOpen,
  name,
  label,
  required,
  handleChange,
  handleBlur,
  tooltip,
  tooltipClickable,
  options,
  placeholder,
  loading,
  multi,
  disabled,
  readOnly
}) => {

  const { control, watch, setValue, formState: { errors }, getValues } = useFormContext();

  const inputError = errors[name];

  const errorStyle = required ? customSelectStyles : customSelectStylesNonRequired;

  const input = watch(name);

  const selectedValues = getValues(name) || [];
  const displayValue = selectedValues.map(option => option?.label).join(', '); 

  useEffect(() => {

    if (input && input.length > 0) {

      input.forEach(val => {
        if (val?.value && typeof val.value === 'string' && val?.label && typeof val.label === 'string') {
          if (isValidASCII(val.value) && isValidASCII(val.label)) {
            return;
          } else {
            // Optionally handle invalid input here, such as warning the user
            const msg = ErrorMessages.InvalidCharacters;
            doSecondaryModalOpen(genericSecondaryModal, { title: 'Invalid Characters', msg: msg });
            setValue(name, input.filter(item => item.value !== val.value));
          }
        }
      });
    };

  }, [input, name, setValue, doSecondaryModalOpen]);


  return (
    <>
      <label
        className={'mr-2 mb-0 w-100'}
        htmlFor={name}>
        {label}{required ? <span className='asterisk-color'>*</span> : <span style={{ fontStyle: 'italic' }}> (optional)</span>}
        {tooltip && <>
          <Tooltip name={name} header={label} content={tooltip} clickable={tooltipClickable} iconStyle={{ marginLeft: '5px' }} />
        </>}
      </label>
      {readOnly ? (
        <Textarea
          className={'read-only-multiselect'}
          id={name}
          name={name}
          readOnly={true}
          value={displayValue}
        />
      ) : (
        <Controller
          control={control}
          name={name}
          render={({
            field: { onChange, onBlur, value, name, ref },
          }) => (
            <Creatable
              inputId={name}
              styles={inputError ? errorStyle : ''}
              required={required}
              isDisabled={disabled}
              options={options}
              isLoading={loading}
              placeholder={placeholder}
              onChange={(e) => {
                onChange(e);
                handleChange && handleChange(e);
              }}
              isMulti={multi}
              onBlur={(e) => {
                onBlur(e);
                handleBlur && handleBlur(e);
              }}
              value={value}
              name={name}
              ref={ref}
            />
          )}
        />
      )}
    </>
  );
}
);
export default CreatableSelectInput;
