import { useState, useCallback, useMemo } from 'react';
import { connect } from 'redux-bundler-react';
import Icon from '@components/icon/Icon';
import { mdiInformation, mdiChevronDown, mdiChevronUp } from '@mdi/js';
import ProfileModal from '@forms/components/modals/Profile';
import LinkButton from '@components/link/linkButton';
import { FileTypes, ProfileRoles, ResourceFiles } from '@src/utils/enums';
import MaintenanceBanner from '@components/maintenance-banner/MaintenanceBanner';
import { Button, Link, Header, Menu, PrimaryNav, NavMenuButton, NavDropDownButton } from '@trussworks/react-uswds';
import InternalLink from '@components/internal-link/InternalLink';
import usaceLogo from '@styles/images/usace-logo-color.svg';
import rrsLogo from '@styles/images/RRS-Black-Red.png';
import { classArray } from '@src/utils/helpers';
import './navigation.scss';

const linkColor = '#58A6FF';

const MenuItemWrapper = ({ onClick, children: menuItemContent }) => (
  <div className='usa-nav__submenu-item' onClick={(e) => { e.stopPropagation(); onClick(); }}>
    {menuItemContent}
  </div>
);

const navClasses = classArray([
  'navbar',
  'navbar-expand-xl',
  'navbar-light',
  'navbar-bg-color',
  'separator',
]);

const NavBar = connect(
  'doAuthLogin',
  'doAuthLogout',
  'doModalOpen',
  'doDownloadFile',
  'selectAuthIsLoggedIn',
  'selectIsUserRoleValid',
  'selectUserProfileData',
  'selectUserIsDeveloper',
  ({
    doAuthLogin,
    doAuthLogout,
    doModalOpen,
    doDownloadFile,
    isUserRoleValid,
    authIsLoggedIn,
    userProfileData,
    userIsDeveloper,
  }) => {
    const [expanded, setExpanded] = useState(false);
    const onClick = () => setExpanded(prevExpanded => !prevExpanded);
    const [learnMoreExpanded, setLearnMoreExpanded] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const shouldShowRequestPage = userProfileData?.role !== ProfileRoles.USACERegulator && userProfileData?.role !== ProfileRoles.ExternalAgency;
    const isProd = import.meta.env.VITE_ENVIRONMENT === 'prod';
    const shouldShowUpdates = !isProd;

    const getCurrentPath = () => {
      const path = window.location.pathname;
      return path.endsWith('/') && path.length > 1 ? path.slice(0, -1) : path;
    };
    const currentPath = getCurrentPath();
    const isActiveLink = useCallback((linkPath) => {
      if (currentPath === '/') {
        return linkPath === '/' || linkPath === '/request';
      }
      return currentPath === linkPath;
    }, [currentPath]);
    const navLinkClass = useCallback((linkPath) => isActiveLink(linkPath) ? 'navbar-link-active' : '', [isActiveLink]);

    const handleKeyPress = useCallback((e) => {
      if (e.key === 'Enter') {
        doAuthLogin();
      }
    }, [doAuthLogin]);

    const handleToggle = useCallback(() => {
      setIsOpen(prevIsOpen => !prevIsOpen);
    }, []);

    const handleLearnMoreClick = useCallback((e) => {
      e.stopPropagation();
      setLearnMoreExpanded(prev => !prev);
    }, []);

    const handleProfileClick = useCallback(() => {
      doModalOpen(ProfileModal, { edit: true });
      handleToggle();
    }, [doModalOpen, handleToggle]);

    const handleLogoutClick = useCallback(() => {
      doAuthLogout();
      handleToggle();
    }, [doAuthLogout, handleToggle]);

    const userMenuItems = useMemo(() => [
      <MenuItemWrapper key='profile' onClick={handleProfileClick}>
        <Button className={'link-profile'}>Profile</Button>
      </MenuItemWrapper>,
      <MenuItemWrapper key='logout' onClick={handleLogoutClick}>
        <Button className={'link-logout'}>Logout</Button>
      </MenuItemWrapper>
    ], [handleProfileClick, handleLogoutClick]);

    const navbarLinks = useMemo(() => {
      const defaultLinks = [
        <InternalLink href='/home' key='Home' content='Home' className={`navbar-link-home ${navLinkClass('/home')}`} />,
        <InternalLink href='/help' key='Help' content='Help' className={`navbar-link-help ${navLinkClass('/help')}`} />
      ];

      return isUserRoleValid ? [
        ...defaultLinks,
        shouldShowRequestPage && <InternalLink href='/' key='Request' content='Request Page' className={`navbar-link-request ${navLinkClass('/request')}`} />,
        <InternalLink href='/dashboard' key='Dashboard' content='Dashboard' className={`navbar-link-dashboard ${navLinkClass('/dashboard')}`} />,
        shouldShowUpdates && <InternalLink href='/updates' key='Updates' content='Updates' className={`navbar-link-updates ${navLinkClass('/updates')}`} />,
        userIsDeveloper && <InternalLink href='/developer' key='Developer' content='Developer' className={`navbar-link-developer ${navLinkClass('/developer')}`} />,
        <>
          <NavDropDownButton
            className='mr-4'
            menuId='userMenu'
            onToggle={handleToggle}
            isOpen={isOpen}
            label={`Logged in as: ${userProfileData?.firstName || ''} ${userProfileData?.lastName || ''}${userProfileData?.role ? ` (${userProfileData?.role})` : ''}`}
          />
          <Menu items={userMenuItems} isOpen={isOpen} id='userMenuOpen'/>
        </>
      ] : [
        ...defaultLinks,
        <Button className='sign-in ml-3 mr-5' key='sign-in' size='big' onClick={doAuthLogin} onKeyUp={handleKeyPress} tabIndex={0}>Sign In</Button>
      ];
    }, [isUserRoleValid, navLinkClass, shouldShowRequestPage, shouldShowUpdates, userIsDeveloper, handleToggle, isOpen, userProfileData, userMenuItems, doAuthLogin, handleKeyPress]);

    return (
      <div className='sticky-top'>
        <div className='navbar-content'>
        </div>
        <Header className={navClasses} basic={true} >
          <div className='navbar-brand ml-4'>
            <Link href={authIsLoggedIn ? '/home' : '/'} title='Regulatory Request System - Home' className='header-logo-link'>
              <img className='usace-logo' alt='USACE Regulatory Logo' src={usaceLogo} height={65} />
              <img className='rrs-logo' alt='RRS Logo'src={rrsLogo} height={39} />
            </Link>
          </div>
          <PrimaryNav items={navbarLinks} mobileExpanded={expanded} onToggleMobileNav={onClick} />
          <NavMenuButton onClick={onClick} label='Menu' />
        </Header>
        <div className={`secondary-bar w-100 ${expanded ? 'expanded' : ''}`}>
          <span className='announcement-banner align-middle'>
            <Icon className='mb-1 mr-1' path={mdiInformation} color='#FFF' focusable={false} size={'14px'} />
            <span>
              The Regulatory Request System (RRS) is currently operating as a beta version &nbsp;
              <span style={{ color: linkColor, textDecoration: 'underline', cursor: 'pointer' }} onClick={handleLearnMoreClick} tabIndex={0}>Learn More</span>
              {learnMoreExpanded ? <Icon focusable={false}path={mdiChevronUp} size={'14px'} /> : <Icon focusable={false} path={mdiChevronDown} size={'14px'} />}
            </span>
          </span>
          {learnMoreExpanded && (
            <div className='additional-text'> For&nbsp;
              <LinkButton style={{ color: linkColor }} onClick={() => doDownloadFile(ResourceFiles.DistrictList, FileTypes.Resource)} title='districts [PDF, 1 page]'  content='districts [PDF, 1 page]' />
                with joint applications processes, please refer to&nbsp;
              <LinkButton style={{ color: linkColor }} onClick={() => doDownloadFile(ResourceFiles.DistrictSpecificInfo, FileTypes.Resource)} title='district specific information [PDF, 4 pages]' content='district specific information [PDF, 4 pages].' />
              <div>The RRS can provide an avenue for the public to submit requests for both pre-application meetings, jurisdictional determinations, and individual and nationwide permits.</div>
              <div>This beta version will continue to be enhanced based on user feedback throughout this year.</div>
            </div>
          )}
        </div>
        <MaintenanceBanner />
      </div>
    );
  }
);
export default NavBar;
