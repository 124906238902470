import { toast } from 'react-toastify';
import statusModal from '@pages/Forms/components/modals/statusModal';
import submissionModal from '@pages/Forms/components/modals/submission';
import * as turf from '@turf/turf';

import L from 'leaflet';

const handleSuccess = (signed, requestType, body, store, dispatch, submissionModal) => {
  signed && toast.success(`${requestType} Request has been successfully submitted!`);
  signed && store.doModalOpen(submissionModal, {
    msg: body?.msg,
    status: body?.status ?? 'Success'
  });
  signed && store.doUpdateRelativeUrl('/dashboard');
  !signed && toast.success(`${requestType} Request has been successfully saved!`);
  !signed && dispatch({ type: 'UPDATE_REQUEST_API_DATA', payload: body?.data });
  !signed && dispatch({ type: 'UPDATE_REQUEST_FORM_DATA', payload: body?.data });
  !signed && dispatch({ type: 'UPDATE_REQUEST_OBJECT', payload: { requestStatus: 'Draft' } });
};

const JDFormDataBundle = {
  name: 'JDFormData',

  getReducer: () => {
    const initialState = {
      jdRequests: [],
      errors: {}
    };

    return (state = initialState, { type, payload }) => {
      switch (type) {
        case 'RESET_JD':
          return initialState;
        case 'UPDATE_JD':
          return { ...state, jdRequests: [{ ...state.jdRequests[0], ...payload }] };
        case 'UPDATE_JD_ERRORS':
          return { ...state, errors: payload };
        default:
          return state;
      };
    };
  },

  selectJDData: (state) => state.JDFormData.jdRequests[0],
  selectJDRequestID: (state) => state.JDFormData.jdRequests?.[0]?.jdRequestID,
  selectJDErrors: (state) => state.JDFormData.errors,

  doFetchJD: (projectID, requestID, jdRequestID, type, requestStatus, isGenerate = false) => ({ apiGet, dispatch, store }) => {
    const requestType = type === '1' ? 'Delineation Report' : 'Jurisdictional Determination';

    !isGenerate && store.doSetLoadingState(true);
    !isGenerate && store.doSetLoadingMessage(`Retrieving ${requestType} Request Information...`);

    store.doResetJDFormData();
    store.doResetRequestData();

    const uri = '/api/JD/getJD?' + new URLSearchParams({
      projectID,
      requestID,
      jdRequestID,
    });

    return apiGet(uri, (err, body) => {
      !isGenerate && store.doSetLoadingMessage('Loading...');
      if (!err && body.status === 'Success') {
        dispatch({ type: 'UPDATE_REQUEST_API_DATA', payload: body.data });
        dispatch({ type: 'UPDATE_REQUEST_FORM_DATA', payload: body.data });
        dispatch({ type: 'UPDATE_REQUEST_OBJECT', payload: { requestStatus: requestStatus } });
        dispatch({ type: 'UPDATE_JD', payload: body.data.request.jdRequests.filter(item => item.jdRequestID === jdRequestID)[0] });
        if (isGenerate) {
          store.doGenerateDraftDocuments();
        }
        else {
          store.doUpdateComplexStateField({ name: 'projectGeometry', value: body.data.location.geometry });
          let bbox;
          let bounds;
          // ONLY EXECUTE SWITCH STATEMENT IF VALUE EXISTS
          switch (body?.data?.location?.geometry?.type) {
            case 'Point':
              //Calculate Centroid
              const point = turf.point(body.data.location.geometry.coordinates);
              const radius = turf.buffer(point, 5, { units: 'miles' });
              bbox = turf.bbox(radius);
              bounds = L.latLngBounds([{ lat: bbox[1], lng: bbox[0] }, { lat: bbox[3], lng: bbox[2] }]);
              store.doUpdateComplexStateField({ name: 'projectBounds', value: bounds });
              break;
            case 'Polygon':
              //Calculate Centroid
              const polygon = turf.polygon(body.data.location.geometry.coordinates);
              bbox = turf.bbox(polygon);
              bounds = L.latLngBounds([{ lat: bbox[1], lng: bbox[0] }, { lat: bbox[3], lng: bbox[2] }]);
              store.doUpdateComplexStateField({ name: 'projectBounds', value: bounds });
              break;
            case 'MultiPolygon':
              //Calculate Centroid
              const multiPolygon = turf.multiPolygon(body.data.location.geometry.coordinates);
              bbox = turf.bbox(multiPolygon);
              bounds = L.latLngBounds([{ lat: bbox[1], lng: bbox[0] }, { lat: bbox[3], lng: bbox[2] }]);
              store.doUpdateComplexStateField({ name: 'projectBounds', value: bounds });
              break;
            case 'LineString':
              //Calculate Centroid
              const lineString = turf.lineString(body.data.location.geometry.coordinates);
              bbox = turf.bbox(lineString);
              bounds = L.latLngBounds([{ lat: bbox[1], lng: bbox[0] }, { lat: bbox[3], lng: bbox[2] }]);
              store.doUpdateComplexStateField({ name: 'projectBounds', value: bounds });
              break;
            case 'MultiLineString':
              //Calculate Centroid
              const multiLineString = turf.multiLineString(body.data.location.geometry.coordinates);
              bbox = turf.bbox(multiLineString);
              bounds = L.latLngBounds([{ lat: bbox[1], lng: bbox[0] }, { lat: bbox[3], lng: bbox[2] }]);
              store.doUpdateComplexStateField({ name: 'projectBounds', value: bounds });
              break;
            default:
              break;
          }
          store.doUpdateSelectedRequest(type);
          store.doUpdateRelativeUrl(`/forms/${requestID}`);
          store.doFetchUserFilesDetails(jdRequestID)
            .finally(() => {
              dispatch({ type: 'SET_LOADING_STATE', payload: false });
            });
        }
      } else {
        store.doSecondaryModalOpen(statusModal, { msg: body?.msg ?? err?.msg ?? 'An error occurred while retrieving the request, please try again', status: body?.status ?? err?.status ?? 'Request Retrieval Error' });
        dispatch({ type: 'SET_LOADING_STATE', payload: false });
      }
    });
  },
  doAddJD: (data) => ({ apiPost, dispatch, store }) => {
    const requestType = store.selectSelectedRequest() === '1' ? 'Delineation Report' : 'Jurisdictional Determination';

    store.doSetLoadingState(true);
    store.doSetLoadingMessage(`Creating ${requestType} Request...`);

    const uri = '/api/JD/addJD';

    return apiPost(uri, data, (err, body) => {
      store.doSetLoadingState(false);
      store.doSetLoadingMessage('Loading...');
      if (!err && body.status === 'Success') {
        dispatch({ type: 'UPDATE_REQUEST_API_DATA', payload: { projectID: body.data.projectID, requestID: body.data.requestID } });
        dispatch({ type: 'UPDATE_REQUEST_FORM_DATA', payload: { projectID: body.data.projectID, requestID: body.data.requestID } });
        dispatch({ type: 'UPDATE_REQUEST_OBJECT', payload: { requestStatus: 'Draft' } });
        dispatch({ type: 'UPDATE_JD', payload: { jdRequestID: body.data.jdRequestID } });
        store.doUpdateRelativeUrl('/forms');
        store.doFetchUserFilesDetails(body.data.jdRequestID);
        store.doModalClose();
      } else {
        store.doSecondaryModalOpen(statusModal, { msg: body?.msg ?? err?.msg ?? 'An error occurred while creating the request, please try again', status: body?.status ?? err?.status ?? 'Request Creation Error' });
      }
    });
  },
  doUploadAndUpdateJD: (data, status) => ({ store }) => {
    const requestType = store.selectSelectedRequest() === '1' ? 'Delineation Report' : 'Jurisdictional Determination';

    store.doSetLoadingState(true);
    store.doSetLoadingMessage(`Uploading ${requestType} Request Files...`);

    if (store.selectValidFiles()?.length > 0) {
      // Format to FormData
      const formdata = new FormData();
      formdata.append('overwrite', false);
      formdata.append('isAnonymous', false);
      store.selectValidFiles().forEach(item => {
        const fileMetaData = {
          ProjectID: store.selectProjectID(),
          RequestID: store.selectRequestID(),
          Version: 0,
          Type: status,
          Tags: item?.tags
        };
        formdata.append('files', item?.file);
        formdata.append('fileMetaData', JSON.stringify(fileMetaData));
      });

      // Upload Files and Save Request
      Promise.all([store.doUploadFiles(formdata)])
        .then(results => {
          if (results?.[0]?.status === 'Success') {
            store.doSetLoadingMessage(`Saving ${requestType} Request...`);
            store.doUpdateJD(data);
            store.doResetUsersFileData();
            store.doFetchUserFilesDetails(store.selectJDRequestID());
          } else {
            // if any of the files failed to upload
            store.doSetLoadingState(false);
            store.doSetLoadingMessage('Loading...');
            store.doResetUsersFileData();
            store.doSecondaryModalOpen(statusModal, {
              status: 'ERROR! Failed Uploads',
              msg: (
                <>
                  <p>{results?.[0]?.msg}</p>
                  <p>Retry saving/submitting your {requestType} Request in a few minutes.</p>
                </>),
              saveText: 'Return to request',
            });
          }
        })
        .catch(e => {
          console.error(e);
        });
    } else {
      // If no files were selected, just update JD
      store.doUpdateJD(data);
    }

  },
  doUpdateJD: (data) => ({ apiPut, dispatch, store }) => {
    const requestType = store.selectSelectedRequest() === '1' ? 'Delineation Report' : 'Jurisdictional Determination';
    store.doSetLoadingState(true);
    store.doSetLoadingMessage(`Saving ${requestType} Request...`);
    const uri = '/api/JD/updateJD';

    return apiPut(uri, data, (err, body) => {
      const signed = Boolean(data?.request?.isSubmit);
      store.doSetLoadingState(false);
      store.doSetLoadingMessage('Loading...');
      if (!err && ['Success', 'Success With Warning'].includes(body?.status)) {
        handleSuccess(signed, requestType, body, store, dispatch, submissionModal);
      }
      else {
        store.doSecondaryModalOpen(statusModal, { msg: body?.msg ?? err?.msg ?? 'An error occurred while saving or submitting the request, please try again', status: body?.status ?? err?.status ?? 'Request Save/Submission Error' });
        dispatch({ type: 'UPDATE_JD_ERRORS', payload: body?.data});
      }
    });
  },
  doUpdateJDRequest: (data) => ({ dispatch, store }) => {
    dispatch({ type: 'UPDATE_JD', payload: data });
    dispatch({ type: 'UPDATE_REQUEST_OBJECT', payload: { jdRequests: [store.selectJDData()] } });
  },
  doResetJDFormData: () => ({ dispatch }) => {
    dispatch({ type: 'RESET_JD' });
  },

};

export default JDFormDataBundle;