import { connect } from 'redux-bundler-react';
import Select from 'react-select';

import Breadcrumb from '@components/breadcrumb';
import PageContent from '@components/page-content';
import PermitProcessGuide from './PermitProcessGuide';
import Jurisdiction from './Jurisdiction/Jurisdiction';
import Permitting from './Permitting/Permitting';
import PublicNotices from './PublicNotices';
import Mitigation from './Mitigation';
import OverviewResources from './OverviewResources/OverviewResources';
import Announcements from './Announcements';

import '../resources.scss';
import SideNavigation from '@components/side-navigation/SideNavigation';

const headerText = {
  'jurisdiction': 'Jurisdiction',
  'mitigation': 'Mitigation',
  'permit-process-guide': 'Permit Process Guide',
  'permitting': 'Permitting',
  'public-notices': 'Public Notices',
  'regulatory-announcements': 'Regulatory Announcements',
  'regulatory-program': 'Regulatory Program Information',
};

const content = {
  'jurisdiction': <Jurisdiction />,
  'mitigation': <Mitigation />,
  'permit-process-guide': <PermitProcessGuide />,
  'permitting': <Permitting />,
  'public-notices': <PublicNotices />,
  'regulatory-announcements': <Announcements />,
  'regulatory-program': <OverviewResources />,
};

const linkProps = [
  {content:'Regulatory Program Information', href:'/home/regulatory-program'},
  {content:'Permit Process Guide', href:'/home/permit-process-guide'},
  {content:'Jurisdiction', href:'/home/jurisdiction'},
  {content:'Permitting', href:'/home/permitting'},
  {content:'Regulatory Announcements', href:'/home/regulatory-announcements'},
  {content:'Mitigation', href:'/home/mitigation'},
];

const ContentLayout = connect(
  'doUpdateRelativeUrl',
  'selectRouteParams',
  ({
    doUpdateRelativeUrl,
    routeParams,
  }) => {
    const resource = routeParams.resource;

    return (
      <div className='content-container'>
        <Breadcrumb pathname={`home/${resource}`} />
        <div style={{ paddingTop: '1%' }}>
          <h1>{headerText[resource] || ''}</h1>
        </div>
        <div className='nav-dropdown'>
          <Select
            name='jumpTo'
            placeholder='Jump To...'
            options={[
              { value: '/home/regulatory-program', label: 'Regulatory Program Information' },
              { value: '/home/permit-process-guide', label: 'Permit Process Guide' },
              { value: '/home/jurisdiction', label: 'Jurisdiction' },
              { value: '/home/permitting', label: 'Permitting' },
              { value: '/home/regulatory-announcements', label: 'Regulatory Announcements' },
              { value: '/home/mitigation', label: 'Mitigation' },
            ]}
            onChange={val => doUpdateRelativeUrl(val.value)}
          />
        </div>
        <div style={{ display: 'flex' }}>
          <div>
            <SideNavigation items={linkProps}/>
          </div>
          <PageContent>
            <div className='page-content-resources'>
              {content[resource] || null}
            </div>
          </PageContent>
        </div>
      </div>
    );
  }
);

export default ContentLayout;
