import React, { useState, useEffect, useCallback, useMemo } from 'react';
import useErrorFocus from '@hooks/useErrorFocus';
import { connect } from 'redux-bundler-react';
import { AgGridReact } from 'ag-grid-react';
import { mdiAccountPlus, mdiCloseOctagon } from '@mdi/js';
import { Alert } from '@trussworks/react-uswds';
import Card from '@components/card';
import FieldHeader from '@forms/components/Form/FieldHeader';
import { Button } from '@trussworks/react-uswds';
import Icon from '@components/icon/Icon';
import EditCellRenderer from '@forms/components/gridCellRenderers/editCellRender';
import AddAdjoiningPropertyOwnerModal from '@forms/components/modals/AddAdjoiningPropertyOwnerModal';
import { ContactTypes } from '@src/utils/enums';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm, FormProvider } from 'react-hook-form';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import ErrorSummary from '@components/error-summary/ErrorSummary';
import '@styles/_buttons.scss';

export const PermitsFormAdjoiningPropertyOwnersMetadata = {
  sectionName: 'Adjoining Property Owners',
  isSection: true,
  lastSection: false,
  firstSection: false,
};

const PermitsFormAdjoiningPropertyOwners = connect(
  'doClearContactType',
  'doModalOpen',
  'doUpdateSectionValidity',
  'doSaveTable',
  'selectRequestAPIData',
  'selectSteps',
  'selectActiveStep',
  'selectPermitData',
  'selectIsReadOnly',
  ({
    doClearContactType,
    doModalOpen,
    doUpdateSectionValidity,
    doSaveTable,
    requestAPIData,
    steps,
    activeStep,
    permitData,
    isReadOnly,
    stepNo,
  }) => {
    const thisSectionStepStatus = useMemo(() => steps.find(step => step.id === stepNo)?.touched, [steps, stepNo]);
    const [rowData, setRowData] = useState([]);
    const requestedPermitType = permitData?.requestedPermitType;

    const schema = () => yup.object().shape({});

    const defaultValues = {
    };

    const methods = useForm({ resolver: yupResolver(schema), mode: 'onBlur', defaultValues: defaultValues });
    const { formState: { errors }, setError, clearErrors, trigger } = methods;
    const errorCount = Object.values(errors).length;

    const columnDefs = [
      { field: 'actions', headerName: 'Actions', width: 65, cellRenderer: 'editRowRenderer', cellRendererParams: { doModalOpen: doModalOpen, modalComponent: AddAdjoiningPropertyOwnerModal, isEdit: true, setRowData: setRowData, rowData: rowData, isReadOnly: isReadOnly } },
      { field: 'firstName', headerName: 'First Name', flex: 1, resizable: true },
      { field: 'middleName', headerName: 'Middle Name', flex: 1, resizable: true },
      { field: 'lastName', headerName: 'Last Name', flex: 1, resizable: true },
      { field: 'address', headerName: 'Address 1', flex: 1, resizable: true },
      { field: 'city', flex: 1, resizable: true },
      { field: 'state', flex: 1, maxWidth: 70 },
      { field: 'country', flex: 1, maxWidth: 70 },
    ];

    const clearRowData = useCallback(() => {
      setRowData([]);
      doClearContactType(ContactTypes.AdjoiningPropertyOwner);
    }, [doClearContactType]);

    useEffect(() => {
      // Load tables from database
      let adjoiningPropertyowners = requestAPIData?.request?.contacts?.filter((contact) => contact.contactType === ContactTypes.AdjoiningPropertyOwner);
      // If country is null, default to US
      adjoiningPropertyowners = adjoiningPropertyowners?.map(contact => contact.country === null ? { ...contact, country: 'US' } : contact);
      adjoiningPropertyowners && setRowData(adjoiningPropertyowners);
    }, [requestAPIData]);

    useEffect(() => {
      // Clear Adjoining Prop Owners rowData if requestedPermitType is not "Standard"
      permitData?.requestedPermitType !== 'Standard Permit' && setRowData([]);
    }, [permitData?.requestedPermitType]);

    useEffect(() => {
      rowData && doSaveTable('adjoiningPropOwner', rowData);
    }, [rowData, doSaveTable]);

    useEffect(() => {
      if (requestedPermitType === 'Standard Permit' && rowData?.length < 1) {
        setError('propertyOwnersTable', { type: 'custom', message: 'Adjoining Property Owners: At least one adjoining property owner is required' });
      }
      else {
        clearErrors('propertyOwnersTable');
      }

    }, [requestedPermitType, rowData, setError, clearErrors]);

    useEffect(() => {
      const validity = errorCount === 0 && rowData?.length > 0;
      doUpdateSectionValidity(PermitsFormAdjoiningPropertyOwnersMetadata.sectionName, requestedPermitType === 'Standard Permit' ? validity : true, stepNo, isReadOnly);
    }, [errorCount, rowData, doUpdateSectionValidity, stepNo, requestedPermitType, isReadOnly]);

    useErrorFocus({ steps, stepNo, activeStep, trigger, noTrigger: true, isReadOnly });

    return (
      <FormProvider {...methods}>
        {errors && thisSectionStepStatus === 'true' && !isReadOnly &&
          <ErrorSummary errors={errors} sectionNo={stepNo} />
        }
        <FieldHeader text='Adjoining Property Owner(s)' >
          {permitData?.requestedPermitType === 'Standard Permit' ? <>
            <p>Provide the names and addresses of all property owners, lessees, etc., whose property adjoins the project site and properties that adjoin the waterbody or aquatic site where the work is being proposed. This information is used to notify the property owners of the project by Public Notice. Information regarding adjoining landowners can typically be obtained through the office of the tax assessor in the counties/boroughs where the project is located.</p>
            <p className='mt-2'>Adjoining property owners can be provided in this section by adding them individually.</p>
          </> :
            <Alert type='warning' heading='IMPORTANT' headingLevel='h2'>
              <p>Adjoining Property Owners Section required for Standard Permits only.</p>
            </Alert>
          }
        </FieldHeader>

        {permitData?.requestedPermitType === 'Standard Permit' && <>
          <Card className='mb-3'>
            <div className='row d-flex w-100 mt-3 pb-3 justify-content-center'>
              <div className='d-flex justify-content-center col-4'>
                <Button
                  className='add-property-owner-button'
                  title='Add an Adjoining Property Owner'
                  size='small'
                  onClick={() => doModalOpen(AddAdjoiningPropertyOwnerModal, { setRowData: setRowData, rowData: rowData, isReadOnly: isReadOnly })}
                  disabled={isReadOnly}
                >
                  <Icon focusable={false} className='mr-1' path={mdiAccountPlus} size={'16px'} />
                  Add an Adjoining Property Owner
                </Button>
              </div>
              <div className='d-flex justify-content-center col-4'>
              </div>
              <div className='d-flex justify-content-center col-4'>
                <Button
                  className={`clear-table-button ${(rowData.length === 0 || isReadOnly) ? 'disabled' : 'hover'}`}
                  title='Clear Adjoining Property Owner(s)'
                  size='small'
                  onClick={() => clearRowData()}
                  disabled={isReadOnly || rowData.length < 1}
                >
                  <Icon focusable={false} path={mdiCloseOctagon} size={'16px'} />
                  Clear Adjoining Property Owner(s)
                </Button>
              </div>
            </div>
            <div className='ag-theme-balham' style={{ height: 400 }}>
              <AgGridReact
                rowData={rowData}
                columnDefs={columnDefs}
                pagination={true}
                paginationAutoPageSize={true}
                rowHeight={35}
                gridOptions={{
                  alwaysShowVerticalScroll: true
                }}
                components={{
                  'editRowRenderer': EditCellRenderer,
                }}
                suppressClickEdit
              />
            </div>
          </Card>
        </>}
      </FormProvider>
    );
  }
);

PermitsFormAdjoiningPropertyOwners.metadata =
  PermitsFormAdjoiningPropertyOwnersMetadata;

export default PermitsFormAdjoiningPropertyOwners;
