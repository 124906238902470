import { useEffect, useState, useCallback } from 'react';
import { connect } from 'redux-bundler-react';

import Card from '@components/card';
import FormHeader from '@forms/components/Form/FormHeader';
import VerticalStepper from '@components/vertical-stepper/VerticalStepper';
import Icon from '@components/icon/Icon';
import { mdiFileDocument } from '@mdi/js';
import SectionButton from '@forms/components/Form/SectionButton';
import JDForm1a, { JDForm1aMetadata } from '@forms/final-forms/JDForm1a';
import JDForm1b, { JDForm1bMetadata } from '@forms/final-forms/JDForm1b';
import GPForm2b, { GPForm2bMetadata } from '@forms/final-forms/GPForm2b';
import IPForm2a, { IPForm2aMetadata } from '@forms/final-forms/IPForm2a';
import PreAppForm3, { PreAppForm3Metadata } from '@forms/final-forms/PreAppForm3';
import { stateAbbreviations } from '@forms/input-forms/_helper';
import ViolationsForm5, { ViolationsForm5Metadata } from '@forms/final-forms/ViolationsForm5';
import { RequestTypeStrings } from '@src/utils/enums';

import './forms.scss';

const Forms = connect(
  'doInitializeStepper',
  'doSetActiveStep',
  'doSetCompleteStep',
  'doSetActiveCompleteStep',
  'doSetTouchedStep',
  'doSetErrorStep',
  'doSetActiveErrorStep',
  'doAddSectionValidity',
  'doUpdateProjectAddressField',
  'doUpdateComplexStateField',
  'doUpdateRelativeUrl',
  'selectSteps',
  'selectActiveStep',
  'selectDashboardProjectData',
  'selectSelectedRequest',
  'selectFormValidation',
  'selectUserProfileData',
  'selectRouteParams',
  'selectIsAnonymous',
  'selectIsNewRequest',
  'selectRequestFormData',
  ({
    doInitializeStepper,
    doSetActiveStep,
    doSetCompleteStep,
    doSetActiveCompleteStep,
    doSetTouchedStep,
    doSetErrorStep,
    doSetActiveErrorStep,
    doAddSectionValidity,
    doUpdateProjectAddressField,
    doUpdateComplexStateField,
    doUpdateRelativeUrl,
    steps,
    activeStep,
    dashboardProjectData,
    selectedRequest,
    formValidation,
    userProfileData,
    routeParams,
    isAnonymous,
    isNewRequest,
    requestFormData,
  }) => {
    const [initialSteps, setInitialSteps] = useState({});
    const [activeForm, setActiveForm] = useState();
    const [activeFormMetadata, setActiveFormMetadata] = useState();
    const [activeSectionMetadata, setActiveSectionMetadata] = useState();
    const [activeSectionHeader, setActiveSectionHeader] = useState();
    const [previousSection, setPreviousSection] = useState();
    const [nextSection, setNextSection] = useState();
    const [formLoaded, setFormLoaded] = useState(false);

    // Check if user is editing/viewing form
    const isEdit = routeParams ? true : false;
    // Check if user is USACE REGULATOR
    const isReadOnly = userProfileData.Role === 'USACE Regulator' ? true : false;

    const updateProjectAddressBundle = useCallback(() => {
      if (dashboardProjectData && dashboardProjectData.length) {
        doUpdateComplexStateField({ name: 'arstate', value: stateAbbreviations[dashboardProjectData.location.state] });
        doUpdateProjectAddressField({ name: 'id', value: dashboardProjectData.project.id });
        doUpdateProjectAddressField({ name: 'name', value: dashboardProjectData.project.projectName });
      }
    }, [dashboardProjectData, doUpdateComplexStateField, doUpdateProjectAddressField]);

    //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    //!!!!!!!!! [1] THIS RUNS FIRST !!!!!!!!!!!!
    //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    useEffect(() => {
      //Loading from dashboard and url has :requestID included
      if (isEdit) {
        if (formLoaded === false) {
          switch (selectedRequest) {
            case '1':
              setActiveForm(<JDForm1a edit={isEdit} />);
              setActiveFormMetadata(JDForm1aMetadata);
              setActiveSectionHeader(RequestTypeStrings[Number(selectedRequest)]);
              break;
            case '2':
              setActiveForm(<JDForm1b edit={isEdit} />);
              setActiveFormMetadata(JDForm1bMetadata);
              setActiveSectionHeader(RequestTypeStrings[Number(selectedRequest)]);
              break;
            case '3':
              setActiveForm(<IPForm2a edit={isEdit} />);
              setActiveFormMetadata(IPForm2aMetadata);
              setActiveSectionHeader(RequestTypeStrings[Number(selectedRequest)]);
              break;
            case '4':
              setActiveForm(<GPForm2b edit={isEdit} />);
              setActiveFormMetadata(GPForm2bMetadata);
              setActiveSectionHeader(RequestTypeStrings[Number(selectedRequest)]);
              break;
            case '5':
              setActiveForm(<PreAppForm3 edit={isEdit} />);
              setActiveFormMetadata(PreAppForm3Metadata);
              setActiveSectionHeader(RequestTypeStrings[Number(selectedRequest)]);
              break;
            case '6':
              setActiveForm(<ViolationsForm5 edit={isEdit} />);
              setActiveFormMetadata(ViolationsForm5Metadata);
              setActiveSectionHeader('Report Potential Violation');
              break;
            default:
              break;
          }
          setFormLoaded(true);
        }
      }
    }, [selectedRequest, isEdit, formLoaded ]);

    //Is this for preapp?
    useEffect(() => {
      (isAnonymous === null) && doUpdateRelativeUrl('/');
    }, [isAnonymous, doUpdateRelativeUrl]);

    //what does this do?
    useEffect(() => {
      updateProjectAddressBundle();
    }, [dashboardProjectData, updateProjectAddressBundle]);

    //ONCE ACTIVE FORM IS SELECTED, INITIALIZE STEPPER AND SECTION VALIDATION STATE OBJECTS
    useEffect(() => {
      const loadSteps = sections => {
        if (sections) {
          const data = sections.map((section, i) => ({
            id: i,
            status: '',
            touched: 'false',
            last: 'false',
            label: section.sectionName
          }));
          setInitialSteps(data);
          data.forEach(section => doAddSectionValidity(section.label, isReadOnly ? true : false, section.id));
        }
      };
      if (activeForm) {
        loadSteps(activeFormMetadata);
      }
    }, [activeForm, activeFormMetadata, doAddSectionValidity, isReadOnly]);

    //SEND INITIAL STEPS TO STEPPER BUNDLE
    useEffect(() => {
      doInitializeStepper(initialSteps);
    }, [initialSteps, doInitializeStepper]);

    //SET METADATA BASED ON ACTIVE FORM (MAYBE CONSOLIDATE THIS INTO ONE USEEFFECT?)
    useEffect(() => {
      if (activeForm && activeStep) {
        setActiveSectionMetadata(activeFormMetadata[activeStep.id]);
      }
    }, [activeForm, activeStep, activeFormMetadata]);

    //REDIRECT WORKAROUND FOR PAGE REFRESH
    useEffect(() => {
      // @TODO: (Temporary Workaround) If user refreshes forms page, redirect to dashboard
      (requestFormData.projectID === null) && doUpdateRelativeUrl('/dashboard');
    }, [doUpdateRelativeUrl, requestFormData.projectID]);

    // STEP VALIDATION LOGIC (CHECKS STATUS EACH SECTION REPORTS TO VALIDATION BUNDLE TOGGLES STEP TO DISPLAY STATUS ON STEPPER)
    useEffect(() => {
      if (steps && activeStep) {
        if (Object.keys(formValidation).length > 0) {
          steps.forEach((step, i) => {
            const isValid = formValidation[step.id]['validity'];
            const isTouched = step.touched;
            const isActive = step.id === activeStep.id;

            if (isValid && isActive && step.status !== 'activecomplete') {
              doSetActiveCompleteStep(step.id);
            } else if (isValid && !isActive && step.status !== 'complete') {
              doSetCompleteStep(step.id);
            } else if (!isValid && isActive && isTouched && step.status !== 'activeerror') {
              doSetActiveErrorStep(step.id);
            } else if (!isValid && !isActive && isTouched && step.status !== 'error') {
              doSetErrorStep(step.id);
            }

          });
        }
      }
    }, [formValidation, doSetActiveCompleteStep, doSetCompleteStep, doSetActiveErrorStep, doSetErrorStep, steps, activeStep, doSetActiveStep]);

    //VALIDATE ALL STEPS FOR FORMS IN PROGRESS ON RETURN
    useEffect(() => {
      if (steps && !isNewRequest) {
        steps.forEach((step) => {
          step.touched !== 'true' && doSetTouchedStep(step.id);
        });
      }
    }, [steps, steps.length, isNewRequest, doSetTouchedStep]);

    // SETS TEXT ON NEXT AND PREVIOUS BUTTONS DYNAMICALLY
    useEffect(() => {
      if (activeSectionMetadata && activeStep) {

        const currentIndex = steps.findIndex(step => step.id === activeStep.id);
        const nextIndex = currentIndex + 1 < steps.length ? currentIndex + 1 : currentIndex;
        const previousIndex = currentIndex - 1 > 0 ? currentIndex - 1 : 0;

        setPreviousSection(steps[previousIndex]['label']);

        setNextSection(!activeSectionMetadata.lastSection && steps[nextIndex] ? steps[nextIndex].label : '');
      }
    }, [activeStep, activeSectionMetadata, setNextSection, setPreviousSection, steps]);


    const handleNextClick = () => {
      if (activeStep) {
        const currentStep = activeStep.id;
        const nextStep = currentStep + 1;
        doSetActiveStep(nextStep);
        document.getElementById('scroll').scrollTop = 0;
        return;
      }
    };

    const handlePreviousClick = () => {
      if (activeStep) {
        const currentStep = activeStep.id;
        const prevStep = currentStep !== 0 ? currentStep - 1 : 0;
        doSetActiveStep(prevStep);
        document.getElementById('scroll').scrollTop = 0;
      }
    };

    return (
      <>
        {(activeSectionMetadata) && (
          <>
            <div className='form-styles'>
              <FormHeader
                header={activeSectionHeader}
                isSection={activeSectionMetadata.isSection}
                disableSaveProgress={isAnonymous}
                isDisabled={isReadOnly}
              />
              <div className='d-flex mt-2 z-index-0'>
                <VerticalStepper />
                <div id='scroll' className='scroll-container mb-2'>
                  {!activeSectionMetadata.firstSection ? (
                    <div onClick={handlePreviousClick} onKeyUp={(e) => e.key === 'Enter' && handlePreviousClick(e)} tabIndex={0}>
                      <SectionButton
                        location='top'
                        section={previousSection}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className='form-container' style={{ position: 'relative' }}>
                    {activeForm}
                  </div>
                  {(!activeSectionMetadata.lastSection && activeSectionMetadata.isSection) ? (<div onClick={handleNextClick} onKeyUp={(e) => e.key === 'Enter' && handleNextClick(e)} tabIndex={0}> <SectionButton location='bottom' section={nextSection} /></div>) : (<></>)}
                  {!activeSectionMetadata.isSection ? (
                    <div onClick={handleNextClick} tabIndex={0} onKeyUp={(e) => e.key === 'Enter' && handleNextClick(e)}>
                      <Card
                        variant='interactive'
                        style={{
                          marginTop: '20px',
                        }}
                      >
                        <Card.Body isContentCentered={true}>
                          <Icon
                            path={mdiFileDocument}
                            style={{
                              marginRight: '5px',
                              verticalAlign: 'middle',
                            }}
                            size={'24px'}
                          />
                          Display Form
                        </Card.Body>
                      </Card>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
);

export default Forms;
