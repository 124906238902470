import React, { useEffect, useState } from 'react';
import { connect } from 'redux-bundler-react';

import ReadOnlyMap from '../../../../../app-components/map/readOnlyMap';
import { SummaryBox, SummaryBoxContent, SummaryBoxHeading, Tag } from '@trussworks/react-uswds';
import { RequestTypeStrings } from '@src/utils/enums';
import SplitLayout from '../../../../../app-components/layout/splitLayout.jsx';
import { calculateGeometryExtent, formatCoordStr } from '../../../../../utils/helpers.jsx';
import DistrictModal from '../../../../../app-components/map/modals/districtModal.jsx';
import LinkButton from '../../../../../app-components/link/linkButton.jsx';
import genericSecondaryModal from '../../../../Forms/components/modals/genericSecondaryModal.jsx';

const ProjectConfirmationTab = connect(
  'doUpdateRequestLocation',
  'doSecondaryModalOpen',
  'selectRequestFormData',
  'selectDistrictResults',
  'selectPlssResults',
  ({
    doUpdateRequestLocation,
    doSecondaryModalOpen,
    requestFormData,
    districtResults,
    plssResults,
    requestType,
    activeTab,
  }) => {
    const location = requestFormData.location;
    const [district, setDistrict] = useState();

    useEffect(() => {

      if (plssResults) {
        doUpdateRequestLocation({ plsssection: plssResults?.FRSTDIVNO, plsstownship: plssResults?.TWNSHPNO, plssrange: plssResults?.RANGENO });
      }

    }, [plssResults, doUpdateRequestLocation]);

    useEffect(() => {
      if (districtResults?.length > 1 && (!district || !districtResults.some(d => d === district))) {
        doSecondaryModalOpen(DistrictModal, { setDistrict });
      } else if (districtResults?.length === 1 && districtResults[0] !== district) {
        setDistrict(districtResults[0]);
      }
    }, [districtResults, district, doSecondaryModalOpen]);

    useEffect(() => {

      doUpdateRequestLocation({ district: district });

    }, [doUpdateRequestLocation, district]);

    return (<>
      <SplitLayout>
        {activeTab === 2 ? <ReadOnlyMap geometry={requestFormData?.location?.geometry} extent={calculateGeometryExtent(requestFormData?.location?.geometry)} height='100%' /> : <></>}
        <>
          <div className='border pt-2 pb-2'>
            <div className='ml-2'>
              <div className='row mt-1'>
                <div className='col-auto'>
                  <span className='text-bold'>Latitude:</span> {location.latitude ? formatCoordStr(location.latitude) : '---'}
                </div>
                <div className='col-auto'>
                  <span className='text-bold'>Longitude:</span> {location.longitude ? formatCoordStr(location.longitude) : '---'}
                </div>
              </div>
              <div className='row mt-1'>
                <div className='col'>
                  <span className='text-bold'>Address:</span> {location.address ? location.address : '---'}
                </div>
              </div>
              <div className='row mt-1'>
                <div className='col'>
                  <span className='text-bold'>Address Line 2:</span> {location.addressTwo ? location.addressTwo : '---'}
                </div>
              </div>
              <div className='row mt-1'>
                <div className='col'>
                  <span className='text-bold'>City:</span> {location.city ? location.city : '---'}
                </div>
              </div>
              <div className='row mt-1'>
                <div className='col'>
                  <span className='text-bold'>State:</span> {location.state ? location.state : '---'}
                </div>
              </div>
              <div className='row mt-1'>
                <div className='col'>
                  <span className='text-bold'>Zip Code:</span> {location.zipcode ? location.zipcode : '---'}
                </div>
              </div>
              <div className='row mt-1'>
                <div className='col'>
                  <span className='text-bold'>County:</span> {location.county ? location.county : '---'}
                </div>
              </div>
              <div className='row mt-1'>
                <div className='col'>
                  <span className='text-bold'>District:</span> {district || '---'}
                </div>
              </div>
              <div className='row mt-1'>
                <div className='col'>
                  <span className='text-bold'>Field Office Code:</span> {location.fieldOfficeCode ? location.fieldOfficeCode : '---'}
                </div>
              </div>
              <div className='row mt-1'>
                <div className='col'>
                  <span className='text-bold'>{`${requestType === '6' ? 'Area of Violation:' : 'Project Area:'}`}</span> {location.projectArea ? location.projectArea : '---'} {location.unitOfMeasurement ? location.unitOfMeasurement : '---'}
                </div>
              </div>
              <div className='row mt-1'>
                <div className='col'>
                  <span className='text-bold'>Parcel Number:</span> {location.parcelNumber ? location.parcelNumber : '---'}
                </div>
              </div>
              <div className='row mt-1'>
                <div className='col-auto'>
                  <span className='text-bold'>PLSS Section:</span> {location.plsssection ? location.plsssection : '---'}
                </div>
                <div className='col-auto'>
                  <span className='text-bold'>PLSS Township:</span> {location.plsstownship ? location.plsstownship : '---'}
                </div>
                <div className='col-auto'>
                  <span className='text-bold'>PLSS Range:</span> {location.plssrange ? location.plssrange : '---'}
                </div>
              </div>
              <div className='row mt-1'>
                <div className='col'>
                  <span className='text-bold'>Nearest Waterbody:</span> {location.nearestWaterbody ? location?.nearestWaterbody?.length > 60 ? <LinkButton onClick={() => doSecondaryModalOpen(genericSecondaryModal, { msg: location.nearestWaterbody, title: 'Nearest Waterbody', hasCloseButton: false })} content='Click to view nearest waterbody' /> : location.nearestWaterbody : '---'}
                </div>
              </div>
              <div className='row mt-1'>
                <div className='col'>
                  <span className='text-bold'>{`${requestType === '6' ? 'Directions to Site Location:' : 'Directions to Project Location:'}`}</span> {location.siteDirections ? location?.siteDirections?.length > 60 ? <LinkButton onClick={() => doSecondaryModalOpen(genericSecondaryModal, { msg: location.siteDirections, title: 'Project/Site Directions', hasCloseButton: false })} content='Click to view project/site directions' /> : location.siteDirections : '---'}
                </div>
              </div>
            </div>
          </div>

        </>
      </SplitLayout>
      <div className='row'>
        <div className='col'>
          <SummaryBox><SummaryBoxContent><SummaryBoxHeading headingLevel='h4'>Please review the following:</SummaryBoxHeading>
            <ul>
              <li>Selected Request:  <Tag background=''>{RequestTypeStrings[requestType]}</Tag></li>
              <li className='mb-1'>Confirm that the Project Location information above is correct. The project's location determines the range of available requests within that specific geographical area. This information cannot be modified using the RRS once a request has been created.</li>
              {requestType === '2' && <li>It is agency practice to honor requests for JDs unless it is clearly impracticable to do so, such as when the USACE is unable to gain access to a site to complete a JD or the USACE lacks other information necessary to respond to the request based on a sound technical record.</li>}
              {requestType === '2' && <li>A jurisdictional determination typically requires a delineation of aquatic resources on the specified property. Persons who would like to request a USACE JD may include an aquatic resources delineation with their request to facilitate timely processing by the USACE. There may be a delay if the USACE needs to conduct the delineation, especially if the project site is large or contains many wetlands, other special aquatic sites, and other waters. Please contact your local USACE Regulatory office to determine current processing times.</li>}
              {requestType === '4' && <li>Please be advised that a Nationwide Permit verification relies on a delineation of Aquatic Resources within the project boundary to note where impacts may be  occurring. Failure to provide this information may add additional time to the review of your request.</li>}
            </ul>
            Press the <span className='text-bold'>Continue</span> button below to begin to your request.<br />
            If changes are required, press the <span className='text-bold'>Previous</span> button to return to the Project Location tab.
          </SummaryBoxContent>
          </SummaryBox>
        </div>
      </div>
    </>
    );
  });
export default ProjectConfirmationTab;
