import { useCallback, useEffect, useState } from 'react';
import { connect } from 'redux-bundler-react';
import { toast } from 'react-toastify';
import { Alert, Label } from '@trussworks/react-uswds';

import { ModalContent, ModalFooter } from '@components/modal';
import SelectInput from '@components/select/Select';
import TextInput from '@components/text-input/TextInput';
import DatePicker from '@components/date-picker/DatePicker';
import Card from '@components/card/card';
import ErrorSummary from '@components/error-summary/ErrorSummary';
import FileInput from '@components/file-input/FileInput';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm, FormProvider } from 'react-hook-form';

import { jddoctypes, violationComplaintDocTypes, preappdoctypes, permitdoctypes } from '@pages/Forms/input-forms/_helper';
import { tagValueRegex } from '@src/utils/regex';
import { ErrorMessages } from '@src/utils/enums';

const AddSupportingDocumentModal = connect(
  'doModalClose',
  'doUpdateSelectedFiles',
  'doResetFileSection',
  'selectSelectedRequest',
  ({
    doModalClose,
    doUpdateSelectedFiles,
    doResetFileSection,
    selectedRequest,
    componentID,
  }) => {
    const [stagedFiles, setStagedFiles] = useState([]);
    const [fileErrors, setFileErrors] = useState(false);
    const dateNow = new Date().toISOString().slice(0, 10);
    const isSelectedFiles = stagedFiles?.length > 0;

    const defaultValues = {
      section: 'Supporting Documents',
      fileName: '',
      componentID: componentID
    };
    const schema = yup.object().shape({
      doctype: yup.string().required('Please select an option'),
      doccreateddate: yup.string().required('Please select a date'),
      doclabel: yup.string().required('Field is required').matches(tagValueRegex, 'Value can only contain alphanumeric characters, whitespace, and the following special characters: _, ., /, =, +, -, :, @').max(256, 'Exceeds character limit'),
      doccitation: yup.string().required('Field is required').matches(tagValueRegex, 'Value can only contain alphanumeric characters, whitespace, and the following special characters: _, ., /, =, +, -, :, @').max(256, 'Exceeds character limit'),
      documents: yup.string().required('Please provide supporting document(s)')
        .test('file-invalid-test',
          'File(s) selected is invalid',
          function (value) { return value !== 'error'; }
        ),
    });

    const methods = useForm({ defaultValues: defaultValues, resolver: yupResolver(schema), mode: 'onBlur' });
    const { formState: { errors, isValid }, setValue, getValues, trigger } = methods;

    const handleDeleteFile = () => {
      doResetFileSection('Supporting Documents');
      setStagedFiles([]);
    };

    const handleSave = () => {
      if (isValid && fileErrors === false) {
        stagedFiles.forEach(item => {
          setValue('file', item);
          setValue('fileName', item.name);
          doUpdateSelectedFiles({...getValues(), isInvalid: false});
        });
        doModalClose();
      } else {
        trigger();
        toast.error('Please fill out all required fields!');
      }
    };

    const handleFileInput = e => {
      const filesArr = Array.from(e?.target?.files) ?? [];
      setStagedFiles(filesArr);
    };

    const setDocTypes = () => {
      switch (selectedRequest) {
        case '1':
          return jddoctypes;
        case '2':
          return jddoctypes;
        case '3':
          return permitdoctypes;
        case '4':
          return permitdoctypes;
        case '5':
          return preappdoctypes;
        case '6':
          return violationComplaintDocTypes;
        default:
          return [];
      }
    };

    const setFileValue = useCallback(() => {
      if (stagedFiles?.length > 0) {
        return fileErrors === true ? 'error' : 'true';
      } else {
        return null;
      }
    }, [fileErrors, stagedFiles]);

    // Set documents to validate that there's at least one file selected
    useEffect(() => {
      setValue('documents', setFileValue());
    }, [setFileValue, setValue]);

    // EDGE CASE: documents being set to a fileList object - reset documents to appropriate value
    useEffect(() => {
      (stagedFiles && fileErrors === true) && setValue('documents', 'error', { shouldValidate: true });
    }, [stagedFiles, fileErrors, setValue]);

    return (
      <ModalContent title='Add Supporting Document Information'>
        <FormProvider {...methods}>
          {errors && <ErrorSummary errors={errors} modalID='addSupportingDocumentsModal' type='modal' />}
          <section className='modal-body' id='addSupportingDocumentsModal'>
            <div className='container-fluid'>
              <div className='width-full padding-bottom-2' id='fieldheader'>
                <Card>
                  <Card.Body>
                    <p>For Document Label and Information Source/Citation, please ensure the values only include letters, digits, white-space, and the following special characters: <code>'_', '.', '/', '=', '+', '-', ':', '@'. </code></p>
                    <p>For uploaded files, please ensure the file name only includes letters, digits, white-space, and the following special characters: <code>'-', '!', '_', '.', ''', '(', ')', '&', '$', '@', '=', ';', '+', ','. </code></p>
                    <p>If any characters outside of these mentioned are included in the file name or Document Label fields you will receive an error message.  To resolve the error, remove the uploaded documents with the "Clear Staged Documents" button, and then reupload the files again ensuring the file name and document labels only include the aforementioned characters.</p>
                  </Card.Body>
                </Card>
              </div>
              <div className='row mt-2'>
                <div className='col-6'>
                  <SelectInput name='doctype' label='Document Type' required>
                    {setDocTypes().map((item, i) => (<option key={i + 2} value={item.value}>{item.text}</option>))}
                  </SelectInput>
                </div>
                <div className='col-6'>
                  <DatePicker name='doccreateddate' max={dateNow} label='Document Created Date' required />
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  <TextInput name='doclabel' label='Document Label' placeholder='Document Label' required />
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  <TextInput name='doccitation' label='Information Source/Citation' placeholder='Information Source/Citation' required />
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  <Label className='border-bottom w-100 padding-bottom-0 mt-3' htmlFor='documents'>Documents<span className='asterisk-color'>*</span></Label>
                  <Alert type='info' className='margin-top-0'>{ErrorMessages.UploadNotice}</Alert>
                  <div className='d-flex flex-column align-items-center margin-top-1 margin-bottom-3'>
                    <FileInput
                      doDeleteFile={() => handleDeleteFile()}
                      id='documents'
                      label='Input accepts multiple files'
                      multiple={true}
                      name='documents'
                      onChange={handleFileInput}
                      onDrop={handleFileInput}
                      setFileErrors={setFileErrors}
                      showDeleteButton={isSelectedFiles}
                      tooltip={<><span className='text-bold'>Note:</span> To select multiple files, while pressing down the control key (Ctrl) click on files that you want to select. Continue to press down the control key until you select all the files you want.</>}
                      required
                      value={stagedFiles}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </FormProvider>
        <ModalFooter
          showCancelButton
          saveText='Add'
          onSave={handleSave}
          customClosingLogic
        />
      </ModalContent>
    );
  });

export default AddSupportingDocumentModal;
