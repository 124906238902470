import { connect } from 'redux-bundler-react';
import Icon from '@components/icon/Icon';
import { mdiLayersTriple, mdiMinus, mdiPlus, mdiMapMarker, mdiTrashCanOutline } from '@mdi/js';
import { Accordion, Alert } from '@trussworks/react-uswds';
import SelectInput from '@components/select/Select';
import TextAreaInput from '@components/textarea/TextArea';
import PermitMap from '@components/map/permitMap';
import DateRange from '@components/date-range/DateRange';
import TextInput from '@components/text-input/TextInput';
import Checkbox from '@components/checkbox/Checkbox';
import { useFormContext } from 'react-hook-form';
import { handleIntegerChange } from '@src/utils/regex';
import { dateBeforeA } from '@src/utils/helpers';
import DatePicker from '@components/date-picker/DatePicker';
import { fullscreenSVG } from '@src/utils/svg';

const svgList = ['Full Screen'];
const mapUseTipsList = [
  { icon: mdiPlus, label: 'Zoom In', text: 'Click the plus sign to zoom in on the map. You can also click one in the map and use the scroll wheel on your mouse to zoom in and out. With the focus of your cursor on the map, you can pan the map with the hand icon' },
  { icon: mdiMinus, label: 'Zoom Out', text: 'Click the minus sign to zoom out on the map. See above for further map navigation tips' },
  { icon: fullscreenSVG, label: 'Full Screen', text: 'Click the full screen icon to zoom out to the original extent of the map' },
  { icon: mdiMapMarker, label: 'Add a Point Feature', text: 'Select the point tool to add a project location as a single point. Click the location of the point' },
  { icon: mdiTrashCanOutline, label: 'Delete Features', text: 'Select this option to delete the location information from the map. You will then need to re-create the project location. After clicking the trash can icon, select Finish, Cancel, or Remove All to delete the location entered on the map' },
  { icon: mdiLayersTriple, label: 'Layers', text: 'Mouse over the layers icon to view the available base maps. The base map can either be viewed as a street map, aerial imagery, or topographic map' },
];

const dredgeMapUseTipsAccordionList = [
  {
    title: 'Map Use Tips',
    content: <>
      {mapUseTipsList.map((item, index) => (
        <p key={index} className={`margin-bottom-0 ${index !== 0 && 'margin-top-105'}`}>
          <span>{svgList.includes(item.label) ? item.icon : <Icon  focusable={false} path={item.icon} size={'24px'} />}</span> {item.label} - {item.text}.
        </p>
      ))}
    </>,
    id: '1',
    headingLevel: 'h3'
  }
];

const PermitsDredgeProject = connect(
  'selectIsReadOnly',
  ({
    isReadOnly,
    handleChange = () => { }
  }) => {
    const { watch } = useFormContext();

    const dredgeType = watch('dredgeType');

    return (
      <div className='container-fluid margin-top-2'>
        <Alert className='margin-left-1' type='info'>The volume of material to be removed and any disposal in waters of the United States must be itemized in the Impact section of this form.</Alert>
        <div className='margin-left-1'>
          <TextAreaInput name='materialTypeComposition' label='Describe the type and composition of the material to be dredged' readOnly={isReadOnly} onChange={handleChange} required />
        </div>
        <div className='margin-left-1'>
          <TextAreaInput name='dredgeMethod' label='Describe the method of dredging (mechanical, suction, clamshell, hydraulic, etc.)' required readOnly={isReadOnly} onChange={handleChange} />
        </div>
        <div className='margin-left-1'>
          <TextAreaInput name='constructionMethods' label='Describe the construction methods' tooltip='Example: Piling installation will take place from a shallow draft barge. Turbidity curtains will be installed prior to construction and removed once construction is complete.' required onChange={handleChange} readOnly={isReadOnly} />
        </div>
        <div className='margin-left-1'>
          <TextInput type='number' valueAsNumber name='estimatedNumberOfEvents' label='Provide the estimated number of dredge events requested for authorization' className='w-25' onChange={(event) => { handleChange(); handleIntegerChange(event); }} readOnly={isReadOnly} />
        </div>
        <label className='margin-top-3 margin-left-1 margin-bottom-0 text-bold'>Provide the time of year/date window for the work:</label>
        <DateRange
          className='col-sm-6'
          nameBefore='workStartDate'
          nameAfter='workEndDate'
          labelBefore='Start Date'
          labelAfter='End Date'
          dateBefore={dateBeforeA}
          onChange={handleChange}
          readOnly={isReadOnly}
          required
        />
        <div className='row'>
          <div className='col-sm-6'>
            <TextInput type='number' valueAsNumber className='width-full' name='durationOfWork' label='Provide the duration of the work' readOnly={isReadOnly} onChange={(event) => { handleChange(); handleIntegerChange(event); }} required />
          </div>
          <div className='col-sm-6'>
            <SelectInput className='width-full' name='unitOfWork' label='Unit of Work' readOnly={isReadOnly} onChange={handleChange} required >
              <option key='2' value='Days'>Days</option>
              <option key='3' value='Hours'>Hours</option>
            </SelectInput>
          </div>
        </div>
        <div className='margin-left-1'>
          <TextInput valueAsNumber className='w-25' name='currentDepth' maxLength={17} label='Provide the current water depth of the area to be dredged (feet)' readOnly={isReadOnly} onChange={handleChange} required />
        </div>
        <fieldset className='margin-top-3' id='dredgeType'>
          <legend className='margin-bottom-0' htmlFor='dredgeType'>Provide the dredge type<span className='asterisk-color'>*</span></legend>
          <div className='w-50'>
            <Checkbox disabled={isReadOnly} id='dredge-type-maintenance' label='Maintenance' name='dredgeType' onChange={handleChange} tile value='Maintenance' />
            <Checkbox disabled={isReadOnly} id='dredge-type-new' label='New Dredging' name='dredgeType' onChange={handleChange} tile value='New'  />
          </div>
        </fieldset>
        {dredgeType && (dredgeType.includes('Maintenance') || dredgeType.includes('New')) && (
          <>
            {(dredgeType?.includes('Maintenance')) && (
              <div className='margin-left-1'>
                <DatePicker min={dateBeforeA} className='w-25' name='lastDredgeCycle' label='Provide the end date of the last dredge cycle' onChange={handleChange} readOnly={isReadOnly} required />
              </div>
            )}
            <div className='margin-top-3 margin-left-1'>Provide the authorized dimensions of the channel and/or depths to be restored (if MAINTENANCE) or constructed (if NEW)<i>(feet)</i>:</div>
            <div className='row'>
              <div className='col-sm-3'>
                <TextInput valueAsNumber className='width-full' maxLength={17} name='authorizedLength' label='Length' readOnly={isReadOnly} onChange={handleChange} required />
              </div>
              <div className='col-sm-3'>
                <TextInput valueAsNumber className='width-full' maxLength={17} name='authorizedWidth' label='Width' readOnly={isReadOnly} onChange={handleChange} required />
              </div>
              <div className='col-sm-3'>
                <TextInput valueAsNumber className='width-full' maxLength={17} name='authorizedDepth' label='Depth' readOnly={isReadOnly} onChange={handleChange} required />
              </div>
            </div>
            <div className='margin-left-1'>
              <TextAreaInput name='disposalPlan' label='Describe the plan for disposal of the dredged material' readOnly={isReadOnly} onChange={handleChange} required />
            </div>
            <div className='margin-left-1'>
              <TextInput type='number' valueAsNumber className='w-25' name='estNumDisposalTrips' label='Estimate the number of disposal trips' readOnly={isReadOnly} onChange={(event) => { handleChange(); handleIntegerChange(event); }} />
            </div>
            <h6 className='border-bottom width-full padding-bottom-1 margin-top-3'>Disposal Location</h6>
            <div className='margin-left-2 padding-bottom-1 margin-bottom-1'>Provide us with the disposal location by </div>
            <ol><li>Completing the fields below.</li><li>Entering information on the map.</li></ol>
            <div className='margin-left-2 padding-bottom-1 margin-bottom-1'>A point location may be entered using the Latitude and Longitude fields below the map or by using the point feature icon on the map</div>
            <div className='margin-left-2 padding-bottom-1 margin-bottom-1'>** latitude and longitude must include at least 6 decimal places.</div>
            <div className='margin-left-2 padding-bottom-1 margin-bottom-1'>To enter a location on the map - Drag the marker across the map to the appropriate location.</div>
            <div className='margin-left-2 padding-bottom-1 margin-bottom-2'>Mouse over each icon within the map to see a brief description of its purpose or expand the Map Use Tips for more in-depth instructions.</div>
            <div className='row'>
              <div className='col'>
                <Accordion bordered items={dredgeMapUseTipsAccordionList} className='margin-bottom-1' />
              </div>
            </div>
            <PermitMap handleChange={handleChange} />
          </>
        )}
      </div >

    );
  }
);

export default PermitsDredgeProject;
