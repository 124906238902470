import { useState, useEffect, useCallback, useMemo } from 'react';
import { connect } from 'redux-bundler-react';
import { AgGridReact } from 'ag-grid-react';
import { mdiAccountPlus, mdiCloseOctagon } from '@mdi/js';
import { Button } from '@trussworks/react-uswds';
import Icon from '@components/icon/Icon';

import Card from '@components/card';
import FieldHeader from '@forms/components/Form/FieldHeader';
import AddPropertyOwnerModal from '@forms/components/modals/AddPropertyOwnerModal';
import SelectInput from '@components/select/Select';
import EditCellRenderer from '@forms/components/gridCellRenderers/editCellRender';
import statusModal from '@forms/components/modals/statusModal';
import LinkButton from '@components/link/linkButton';
import ErrorSummary from '@components/error-summary/ErrorSummary';
import RightOfEntryUpload from '@forms/components/Form/RightOfEntryUpload';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm, FormProvider } from 'react-hook-form';

import { validatePropertyOwners } from '@forms/validation/validations';
import useErrorFocus from '@hooks/useErrorFocus';
import { ContactTypes, ContactsFormNames, FileTypes, TemplateFiles } from '@src/utils/enums';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

export const PreAppFormPropertyOwnersMetadata = {
  sectionName: 'Property Owners',
  isSection: true,
  lastSection: false,
  firstSection: false,
};

const PreAppFormPropertyOwners = connect(
  'doDownloadFile',
  'doDownloadPopulatedFile',
  'doClearContactType',
  'doModalOpen',
  'doSecondaryModalOpen',
  'doUpdateSectionValidity',
  'doUpdatePreAppRequest',
  'doSaveTable',
  'selectFileObjectPropOwners',
  'selectRequestAPIData',
  'selectSteps',
  'selectActiveStep',
  'selectIsReadOnly',
  ({
    doDownloadFile,
    doDownloadPopulatedFile,
    doClearContactType,
    doModalOpen,
    doSecondaryModalOpen,
    doUpdateSectionValidity,
    doUpdatePreAppRequest,
    doSaveTable,
    fileObjectPropOwners,
    requestAPIData,
    steps,
    activeStep,
    isReadOnly,
    componentID,
    stepNo
  }) => {
    const thisSectionStepStatus = useMemo(() => steps.find(step => step.id === stepNo)?.touched, [steps, stepNo]);
    const [rowData, setRowData] = useState([]);
    const propertyOwnerTableLength = rowData?.length;

    const schema = (propertyOwnerTableLength) => yup.object().shape({
      rightOfEntryFile: yup.string().test('file-invalid-test',
        'Right-of-Entry: File selected is invalid',
        function (value) { return value !== 'error'; }
      ),
      hasAddPropertyOwners: yup.string().required('Please select an option'),
      propertyOwnersTable: yup.boolean().when('hasAddPropertyOwners', { is: (val) => ((val === 'true' || val === true) && propertyOwnerTableLength < 1), then: () => yup.boolean().required('Property Owners: At least one property owner is required') }),
      ownerEntryComments: yup.string().nullable(),
    }, [['rightOfEntryFile', 'rightOfEntryFile']]);

    const defaultValues = {
      hasAddPropertyOwners: requestAPIData?.request?.preApplications?.[0]?.hasAddPropertyOwners !== null ? requestAPIData?.request?.preApplications?.[0]?.hasAddPropertyOwners : null,
      ownerEntryComments: requestAPIData?.request?.ownerEntryComments ?? '',
    };

    const methods = useForm({ resolver: yupResolver(schema(propertyOwnerTableLength)), mode: 'onBlur', defaultValues: defaultValues });
    const { formState: { errors }, watch, setError, clearErrors, trigger } = methods;
    useErrorFocus({ steps, stepNo, activeStep, trigger, isReadOnly });
    const errorCount = Object.entries(errors)?.length;
    const hasAddPropertyOwners = watch('hasAddPropertyOwners');

    const columnDefs = [
      { field: 'actions', headerName: 'Actions', width: 65, cellRenderer: 'editRowRenderer', cellRendererParams: { doModalOpen: doModalOpen, modalComponent: AddPropertyOwnerModal, isEdit: true, setRowData: setRowData, rowData: rowData, isReadOnly: isReadOnly } },
      { field: 'firstName', headerName: 'First Name', flex: 1, resizable: true },
      { field: 'middleName', headerName: 'Middle Name', flex: 1, resizable: true },
      { field: 'lastName', headerName: 'Last Name', flex: 1, resizable: true },
      { field: 'address', headerName: 'Address 1', flex: 1, resizable: true },
      { field: 'city', flex: 1, resizable: true },
      { field: 'state', flex: 1, maxWidth: 70 },
      { field: 'country', flex: 1, maxWidth: 70 },
    ];

    const clearRowData = useCallback(() => {
      setRowData([]);
      doClearContactType(ContactTypes.PropertyOwner);
    }, [doClearContactType]);

    // Bulk Upload Data Validation
    useEffect(() => {
      if (fileObjectPropOwners) {
        const firstArray = Object.values(fileObjectPropOwners).find(value => Array.isArray(value)) || [];
        const formattedArray = firstArray?.map((owner) => ({ ...owner, contactType: ContactTypes.PropertyOwner, parcels: owner?.parcels?.split(',').filter(e => e !== '').map(parcel => ({ parcelNumber: parcel })) }));
        const validationResults = validatePropertyOwners(formattedArray);
        if (!validationResults?.valid) {
          doSecondaryModalOpen(statusModal, validationResults);
        }
        validationResults?.valid && setRowData((rowData) => [...rowData, ...formattedArray]);
      }
    }, [fileObjectPropOwners, doSecondaryModalOpen]);

    useEffect(() => {
      // Load tables from database
      let propertyowners = requestAPIData?.request?.contacts?.filter((contact) => contact.contactType === ContactTypes.PropertyOwner);
      // If country is null, default to US
      propertyowners = propertyowners?.map(contact => contact.country === null ? { ...contact, country: 'US' } : contact);
      propertyowners && setRowData(propertyowners);
    }, [requestAPIData]);

    useEffect(() => {
      if (rowData?.length > 0) {
        (hasAddPropertyOwners === 'false' || hasAddPropertyOwners === false) && clearRowData();
      }
    }, [rowData, hasAddPropertyOwners, clearRowData]);

    useEffect(() => {
      if ((hasAddPropertyOwners === 'true' || hasAddPropertyOwners === true) && rowData?.length < 1) {
        setError('propertyOwnersTable', { type: 'custom', message: 'Property Owners: At least one property owner is required' });
      } else {
        clearErrors('propertyOwnersTable');
      }
    }, [hasAddPropertyOwners, rowData?.length, setError, clearErrors]);

    useEffect(() => {
      const valid = ((hasAddPropertyOwners === 'false' || hasAddPropertyOwners === false) ? true : ((hasAddPropertyOwners === 'true' || hasAddPropertyOwners === true) && (rowData?.length > 0)));
      const validity = errorCount === 0 && valid;
      doUpdateSectionValidity(PreAppFormPropertyOwnersMetadata.sectionName, validity, stepNo, isReadOnly);
    }, [errorCount, rowData, hasAddPropertyOwners, doUpdateSectionValidity, stepNo, isReadOnly]);

    useEffect(() => {
      rowData && doSaveTable('propOwners', rowData);
    }, [rowData, doSaveTable]);

    useEffect(() => {
      doUpdatePreAppRequest({ hasAddPropertyOwners: hasAddPropertyOwners });
    }, [hasAddPropertyOwners, doUpdatePreAppRequest]);

    return (
      <FormProvider {...methods}>
        {errors && thisSectionStepStatus === 'true' && !isReadOnly &&
          <ErrorSummary errors={errors} sectionNo={stepNo} />
        }
        <FieldHeader text='Property Owner(s)' subtext='If the USACE determines a site visit is needed, you must include contact information for all current deeded property owners and provide a signed right-of-entry for each owner. A right-of-entry form allows the USACE to access the project area when conducting a site investigation or jurisdictional determination. This form ensures that the USACE has the legal right to enter the property.'><p>
          <LinkButton onClick={() => doDownloadFile(TemplateFiles.RightOfEntry, FileTypes.Template)} title='Download a blank Right of Entry form' content='Download a blank Right of Entry form' /> to complete or <LinkButton onClick={() => doDownloadPopulatedFile(ContactsFormNames.RightOfEntry, TemplateFiles.RightOfEntry)} title='download a Right of Entry form populated' content='download a Right of Entry form populated' /> with the information you entered. and upload the completed document in the section below.</p>
        </FieldHeader>

        <RightOfEntryUpload 
          componentID={componentID}
          subText={<>
            <p>The property owner or an easement holder must provide right-of-entry to the U.S. Army Corps of Engineers and be a duly authorized owner of record of the property.</p>
            <p> If the agent signs the right-of-entry, they must have an agent authorization signed by the property owner.</p>
          </>}
        />

        <div className='ml-2' id='propertyOwnersTable'>
          <SelectInput name='hasAddPropertyOwners' label='Are there any property owners other than the applicant?' required className='w-50 mb-3' readOnly={isReadOnly}>
            <option key='1' value='true'>Yes</option>
            <option key='2' value='false'>No</option>
          </SelectInput>
        </div>

        {(hasAddPropertyOwners === 'true' || hasAddPropertyOwners === true) &&
          <>
            <Card className='mb-3'>
              <div className='row d-flex w-100 mt-3 pb-3 justify-content-center'>
                <div className='d-flex justify-content-center col-4'>
                  <Button
                    className='add-property-owner-button'
                    title='Add a Property Owner'
                    size='small'
                    onClick={() => doModalOpen(AddPropertyOwnerModal, { setRowData: setRowData, rowData: rowData, isReadOnly: isReadOnly })}
                    disabled={isReadOnly}
                  >
                    <Icon focusable={false} className='mr-1' path={mdiAccountPlus} size={'16px'} />
                    Add a Property Owner
                  </Button>
                </div>
                <div className='d-flex justify-content-center col-4'>
                  {/* <Button
                    icon={<Icon path={mdiAccountMultiplePlus} size={'16px'} />}
                    text='Bulk Upload'
                    size='small'
                    onClick={() => doModalOpen(AddBulkUploadPropertyOwnerModal)}
                    isDisabled={true}
                  /> */}
                </div>
                <div className='d-flex justify-content-center col-4'>
                  <Button
                    className={`clear-table-button ${(rowData.length === 0 || isReadOnly) ? 'disabled' : 'hover'}`}
                    title='Clear Property Owner(s)'
                    size='small'
                    onClick={() => clearRowData()}
                    disabled={isReadOnly || rowData.length < 1}
                  >
                    <Icon focusable={false} path={mdiCloseOctagon} size={'16px'} />
                    Clear Property Owner(s)
                  </Button>
                </div>
              </div>
              <div className='ag-theme-balham' style={{ height: 400 }}>
                <AgGridReact
                  rowData={rowData}
                  columnDefs={columnDefs}
                  pagination={true}
                  paginationAutoPageSize={true}
                  rowHeight={35}
                  gridOptions={{
                    alwaysShowVerticalScroll: true
                  }}
                  components={{
                    'editRowRenderer': EditCellRenderer,
                  }}
                  suppressClickEdit
                />
              </div>
            </Card>
          </>
        }
      </FormProvider>
    );
  }
);

PreAppFormPropertyOwners.metadata = PreAppFormPropertyOwnersMetadata;

export default PreAppFormPropertyOwners;
